import React from 'react';
import { ConfigProvider } from 'antd';
import GlobalStyle from './styles/GlobalStyle';
import { AppRouter } from './components/router/AppRouter';
import { usePWA } from './hooks/usePWA';
import { useAppSelector } from './hooks/reduxHooks';
import { themeObject } from './styles/themes/themeVariables';
import UserContextProvider from './context/UserContext';
import zhCN from 'antd/lib/locale/zh_CN';
import msMY from 'antd/lib/locale/ms_MY';
import { useTranslation } from 'react-i18next';

const App: React.FC = () => {
  const theme = useAppSelector((state) => state.theme.theme);
  const { i18n } = useTranslation();

  usePWA();

  return (
    <UserContextProvider>
      <meta name="theme-color" content={themeObject[theme].primary} />
      <GlobalStyle />
      <ConfigProvider locale={i18n.language === 'zh' ? zhCN : i18n.language === 'ms' ? msMY : undefined}>
        <AppRouter />
      </ConfigProvider>
    </UserContextProvider>
  );
};

export default App;
