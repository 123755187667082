import { IAgent } from './agentTypes';
import { IPayment } from './paymentTypes';

export enum CommissionStatusEnum {
  Pending = 'Pending',
  Paid = 'Paid',
  Due = 'Due',
  Cancelled = 'Cancelled',
  Late = 'Late',
}

export interface ICommission {
  id: string;
  amount: number;
  status: CommissionStatusEnum;
  agent: IAgent;
  payment: IPayment;
}
