import { Typography } from 'antd';
import { t } from 'i18next';
import React from 'react';
import useCountDown from 'react-countdown-hook';

const { Text } = Typography;
interface Props {
  countdownDuration?: number;
}

const CountdownTimer: React.FC<Props> = ({ countdownDuration = 0 }) => {
  const [timeLeft, { start }] = useCountDown(countdownDuration, 1000);
  React.useEffect(() => {
    start();
  }, []);

  const one_minute = 1000 * 60;

  const days = Math.floor(timeLeft / (one_minute * 60 * 24));
  const hours = Math.floor((timeLeft % (one_minute * 60 * 24)) / (one_minute * 60));
  const minutes = Math.floor((timeLeft % (one_minute * 60)) / one_minute);
  const seconds = Math.floor((timeLeft % one_minute) / 1000);

  const padDuration = (number: number) => {
    return String(number).padStart(2, '0');
  };

  if (timeLeft)
    return (
      <>
        {days ? `${days}d` : ''} {hours ? `${padDuration(hours)}h` : ''} {padDuration(minutes)}m {padDuration(seconds)}s
      </>
    );
  else return <Text type="danger">{t('Timer expired')}</Text>;
};

export default CountdownTimer;
