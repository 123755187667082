import { AgentStatusEnum } from '@app/types/agentTypes';
import {
  ApprovalMatrixAllowedEnum,
  ApprovalMatrixConditionEnum,
  ApprovalMatrixParameterEnum,
  ApprovalMatrixTypeEnum,
} from '@app/types/approvalMatrixTypes';
import {
  LotDirectionEnum,
  LotLandAreaEnum,
  LotLandTypeEnum,
  LotUrnAreaEnum,
  LotUrnTypeEnum,
} from '@app/types/lotTypes';
import { MiscProductSoldWithLotEnum, MiscProductStatusEnum } from '@app/types/miscProductTypes';
import {
  PaymentCommissionStatusEnum,
  PaymentModeEnum,
  PaymentStatusEnum,
  PaymentTypeEnum,
} from '@app/types/paymentTypes';
import { PaymentVerificationPurposeEnum, paymentVerificationStageStep } from '@app/types/paymentVerificationTypes';
import { ProductStatusEnum } from '@app/types/productTypes';
import { PromotionApplicableEnum, PromotionStatusEnum } from '@app/types/promotionTypes';
import { RefundStatusEnum, RefundTypeEnum } from '@app/types/refundTypes';
import { SalePaymentTypeEnum, SaleStatusEnum } from '@app/types/salesTypes';
import { StaffRoleEnum, StaffStatusEnum } from '@app/types/staffTypes';
import { ZoneCategoryEnum } from '@app/types/zoneTypes';
import {
  displayEnumString,
  getApprovalTypeDisplay,
  getPaymentPaymentTypeDisplay,
  getSalePaymentDisplay,
  getStaffRoleName,
} from '@app/utils/utils';
import { Select } from 'antd';

const { Option, OptGroup } = Select;
export interface Dropdown {
  value: string | number;
  label: string;
}

export const getEnumValues = (enumToDeconstruct: Record<string, unknown>): Array<keyof typeof enumToDeconstruct> => {
  return Object.values(enumToDeconstruct) as Array<keyof typeof enumToDeconstruct>;
};

export const generateDropdown = (options: Array<string>, enumMode = false) =>
  options.map((option) => ({
    value: option,
    label: enumMode ? displayEnumString(option) : option,
  }));

export const languageTranslationOption: Dropdown[] = [
  { value: 'en-GB', label: 'English' },
  { value: 'zh', label: 'Mandarin' },
  { value: 'ms', label: 'Bahasa Melayu' },
];

export const malaysiaStateList: Dropdown[] = generateDropdown([
  'Johor',
  'Kedah',
  'Kelantan',
  'Melacca',
  'Negeri Sembilan',
  'Pahang',
  'Penang',
  'Perak',
  'Perlis',
  'Selangor',
  'Terengganu',
  'Sabah',
  'Sarawak',
  'Kuala Lumpur',
  'Labuan',
  'Putrajaya',
  'NA',
]);

export const availableCountryList: Dropdown[] = generateDropdown(['Malaysia', 'Singapore']);

export const customerSourceList: Dropdown[] = generateDropdown([
  'Facebook, Instagram, Xiao Hong, TikTok, YouTube',
  'Website, Google, Yahoo',
  'Flyer, Brochure, Voucher',
  'Bunting, Billboard, Digital Screen',
  'Newspaper, Radio',
  'Introducer, Agent',
  'Casket Shops, Undertaker',
  'Friends & Family',
  'Existing Clients',
  'Other',
]);

export const bankList: Dropdown[] = generateDropdown([
  'Affin Bank Berhad',
  'Affin Islamic Bank Berhad',
  'Al Rajhi Banking & Investment Corporation (Malaysia) Berhad',
  'Alliance Bank Malaysia Berhad',
  'Alliance Islamic Bank Berhad',
  'AmBank (M) Berhad',
  'AmBank Islamic Berhad',
  'Asian Finance Bank Berhad',
  'Bangkok Bank Berhad',
  'Bank Islam Malaysia Berhad (KL)',
  'Bank Muamalat Malaysia Berhad',
  'Bank of America Malaysia Berhad',
  'Bank of China (Malaysia) Berhad',
  'Bank of Tokyo-Mitsubishi UFJ (Malaysia) Berhad',
  'BNP Paribas Malaysia Berhad',
  'CIMB Bank Berhad',
  'CIMB Islamic Bank Berhad',
  'Citibank Berhad',
  'Deutsche Bank (Malaysia) Berhad',
  'Hong Leong Bank Berhad',
  'Hong Leong Islamic Bank Berhad',
  'HSBC Amanah Malaysia Berhad',
  'HSBC Bank Malaysia Berhad',
  'India International Bank (Malaysia) Berhad',
  'Industrial and Commercial Bank of China (Malaysia) Berhad',
  'J.P. Morgan Chase Bank Berhad',
  'Kuwait Finance House (Malaysia) Berhad',
  'Malayan Banking Berhad',
  'Maybank Islamic Berhad',
  'Mizuho Bank (Malaysia) Berhad',
  'National Bank of Abu Dhabi Malaysia Berhad',
  'OCBC Al-Amin Bank Berhad',
  'OCBC Bank (Malaysia) Berhad',
  'Public Bank Berhad',
  'Public Islamic Bank Berhad',
  'RHB Bank Berhad',
  'RHB Islamic Bank Berhad',
  'Standard Chartered Bank Malaysia Berhad',
  'Standard Chartered Saadiq Berhad',
  'Sumitomo Mitsui Banking Corporation Malaysia Berhad',
  'The Bank of Nova Scotia Berhad',
  'The Royal Bank of Scotland Berhad',
  'United Overseas Bank (Malaysia) Bhd.',
]);

export const landTypeOption: Dropdown[] = generateDropdown(getEnumValues(LotLandTypeEnum));
export const urnTypeOption: Dropdown[] = generateDropdown(getEnumValues(LotUrnTypeEnum));
export const statusOption: Dropdown[] = generateDropdown(getEnumValues(ProductStatusEnum));

export const productDirectionOption: Dropdown[] = generateDropdown(getEnumValues(LotDirectionEnum));
export const productCategoryOption: Dropdown[] = generateDropdown(getEnumValues(ZoneCategoryEnum));
export const productTypeOption = (
  <>
    <OptGroup label="Land">
      {getEnumValues(LotLandTypeEnum).map((type) => (
        <Option key={type}>{type}</Option>
      ))}
    </OptGroup>
    <OptGroup label="Urn">
      {getEnumValues(LotUrnTypeEnum).map((type) => (
        <Option key={type}>{type}</Option>
      ))}
    </OptGroup>
  </>
);
export const productAreaOption = (
  <>
    <OptGroup label="Land">
      {getEnumValues(LotLandAreaEnum).map((area) => (
        <Option key={area}>{area}</Option>
      ))}
    </OptGroup>
    <OptGroup label="Urn">
      {getEnumValues(LotUrnAreaEnum).map((area) => (
        <Option key={area}>{area}</Option>
      ))}
    </OptGroup>
  </>
);
export const productStatusOption: Dropdown[] = generateDropdown(getEnumValues(ProductStatusEnum));

export const miscellaneousProductStatusOption: Dropdown[] = generateDropdown(getEnumValues(MiscProductStatusEnum));
export const miscellaneousProductSaleStatusOption: Dropdown[] = generateDropdown([MiscProductStatusEnum.Available]);
export const miscellaneousProductSoldWithLotOption: Dropdown[] = generateDropdown(
  getEnumValues(MiscProductSoldWithLotEnum),
);

export const salesPaymentTypeOption: Dropdown[] = [
  SalePaymentTypeEnum.Instant,
  SalePaymentTypeEnum.Cash,
  SalePaymentTypeEnum.Instalment,
].map((salePaymentType) => ({
  label: getSalePaymentDisplay(salePaymentType as SalePaymentTypeEnum),
  value: salePaymentType,
}));

export const salesStatusOption: Dropdown[] = generateDropdown(getEnumValues(SaleStatusEnum), true);

export const miscellaneousSalesPaymentTypeOption: Dropdown[] = [
  SalePaymentTypeEnum.Instant,
  SalePaymentTypeEnum.CashPaymentPlan,
  SalePaymentTypeEnum.Cash,
  SalePaymentTypeEnum.Instalment,
].map((salePaymentType) => ({
  label: getSalePaymentDisplay(salePaymentType as SalePaymentTypeEnum),
  value: salePaymentType,
}));

export const paymentPaymentTypeOption: Dropdown[] = [
  PaymentTypeEnum.Instant,
  PaymentTypeEnum.Cash,
  PaymentTypeEnum.Instalment,
  PaymentTypeEnum.Booking,
  PaymentTypeEnum.BookingExtension,
  PaymentTypeEnum.EditBooking,
  PaymentTypeEnum.LotResale,
].map((paymentType) => ({
  label: getPaymentPaymentTypeDisplay(paymentType as PaymentTypeEnum),
  value: paymentType,
}));

export const paymentModeOption: Dropdown[] = [
  { value: PaymentModeEnum.Online, label: 'Stripe' },
  { value: PaymentModeEnum.Offline, label: 'Offline' },
];
export const paymentStatusOption: Dropdown[] = generateDropdown([
  PaymentStatusEnum.Paid,
  PaymentStatusEnum.Due,
  PaymentStatusEnum.Late,
  PaymentStatusEnum.Cancelled,
  PaymentStatusEnum.Pending,
  PaymentStatusEnum.Refunded,
]);
export const paymentCommissionStatusOption: Dropdown[] = generateDropdown(getEnumValues(PaymentCommissionStatusEnum));
export const paymentOfflinePaymentOption: Dropdown[] = generateDropdown([
  'Cash',
  'Cheque',
  'Online Transfer',
  'Credit Card - AMB',
  'Credit Card - HLB',
  'Credit Card - PBB',
  'Moto',
  'E-Wallet',
  'Ipay88',
]);
export const paymentPauseDurationOption: Dropdown[] = [
  { label: '1 month', value: 1 },
  { label: '2 months', value: 2 },
  { label: '3 months', value: 3 },
];

export const staffRoleOption: Dropdown[] = getEnumValues(StaffRoleEnum).map((staffRole) => ({
  label: getStaffRoleName(staffRole as StaffRoleEnum),
  value: staffRole,
}));

export const staffStatusOption: Dropdown[] = generateDropdown(getEnumValues(StaffStatusEnum));
export const staffRegistrationStatusOption: Dropdown[] = generateDropdown([
  StaffStatusEnum.Active,
  StaffStatusEnum.Blocked,
]);

export const agentCommissionStatusOption: Dropdown[] = generateDropdown([
  PaymentStatusEnum.Due,
  PaymentStatusEnum.Paid,
  PaymentStatusEnum.Late,
]);
export const agentStatusOption: Dropdown[] = generateDropdown(getEnumValues(AgentStatusEnum));

export const promotionApplicableOption: Dropdown[] = generateDropdown(getEnumValues(PromotionApplicableEnum));
export const promotionStatusOption: Dropdown[] = generateDropdown(getEnumValues(PromotionStatusEnum));

export const companyIdOption: Dropdown[] = generateDropdown(['PMPB', 'PMPI', 'PMUC']);
export const companyLocationOption: Dropdown[] = generateDropdown(['101', '102', '103', '104', '105']);

export const approvalMatrixAllowedOption = generateDropdown(getEnumValues(ApprovalMatrixAllowedEnum));
export const approvalMatrixConditionOptions: Dropdown[] = [
  { label: 'is less than or equal to ( ≤ )', value: ApprovalMatrixConditionEnum.LessThanOrEqualTo },
  { label: 'is greater than or equal to ( ≥ )', value: ApprovalMatrixConditionEnum.GreaterThanOrEqualTo },
  { label: 'is equal to ( = )', value: ApprovalMatrixConditionEnum.EqualTo },
  // { label: 'is between or equal to', value: ApprovalMatrixConditionEnum.BetweenOrEqualTo },
];
export const approvalMatrixBookingExtensionParameterOptions: Dropdown[] = [
  { label: 'Any circumstance', value: ApprovalMatrixParameterEnum.AnyCircumstance },
  { label: 'Booking Fee', value: ApprovalMatrixParameterEnum.BookingFee },
];
export const approvalMatrixBeneficiaryNomineeParameterOptions: Dropdown[] = [
  { label: 'Any circumstance', value: ApprovalMatrixParameterEnum.AnyCircumstance },
  { label: 'Admin Fee', value: ApprovalMatrixParameterEnum.AdminFee },
];

export const approvalPaymentTypeOption: Dropdown[] = miscellaneousSalesPaymentTypeOption;
export const approvalApprovalTypeOption: Dropdown[] = getEnumValues(ApprovalMatrixTypeEnum).map((type) => ({
  label: getApprovalTypeDisplay(type),
  value: type,
}));

export const refundStatusOption: Dropdown[] = generateDropdown(
  [RefundStatusEnum.Outstanding, RefundStatusEnum.InProcess, RefundStatusEnum.Completed],
  true,
);
export const refundTypeOption: Dropdown[] = generateDropdown(getEnumValues(RefundTypeEnum));

export const benficiaryNomineeGenderOption: Dropdown[] = generateDropdown(['Male', 'Female']);
export const benficiaryNomineeRelationshipOption: Dropdown[] = generateDropdown([
  'Wife',
  'Husband',
  'Sister',
  'Cousin',
]);

export const paymentVerificationPurposeOption: Dropdown[] = generateDropdown(
  getEnumValues(PaymentVerificationPurposeEnum),
  true,
);
export const paymentVerificationStageOption: Dropdown[] = paymentVerificationStageStep?.map((stage) => ({
  label: stage,
  value: stage.replace(/\s/g, '').toLowerCase(),
}));
