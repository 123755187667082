import React from 'react';
import * as S from '../Header.styles';
import { languageTranslationOption } from '@app/constants/dropdownList';
import { Button, Image, Space } from 'antd';
import LogoutIcon from 'assets/iconoir_log-out.svg';
import { useResponsive } from '@app/hooks/useResponsive';
import { CreateSaleButton } from '@app/components/common/CreateSaleButton';

interface HeaderContentProps {
  onChangeLang: (text: any) => void;
  lang: string;
  logout: () => void;
}

export const HeaderContent: React.FC<HeaderContentProps> = ({ onChangeLang, lang, logout }) => {
  const { mobileOnly } = useResponsive();

  return (
    <Space direction="horizontal" size={24}>
      <CreateSaleButton type="ghost" />
      {!mobileOnly && (
        <S.StyledSelect value={lang} onChange={(val) => onChangeLang(val)} options={languageTranslationOption} />
      )}
      <Button size={'small'} onClick={logout} type="text">
        <Image preview={false} src={LogoutIcon} />
      </Button>
    </Space>
  );
};
