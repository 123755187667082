import { gql } from '@apollo/client';

export const GET_STAFF = gql`
  query GetStaff($id: ID, $resetKey: ID) {
    staff(id: $id, resetKey: $resetKey) {
      firstName
      lastName
      phone
      email
      role
      resetKeyExpires
      access_token
      refresh_token
      uid
    }
  }
`;

export const GET_ALL_STAFF = gql`
  query AllStaff($input: GetAllStaffInput) {
    allStaff(input: $input) {
      staffs {
        id
        firstName
        lastName
        fullName
        chineseName
        phone
        email
        role
        status
        closedSales
        uid
        isSuspended
        createdAt
        updatedAt
        createdBy {
          firstName
          lastName
        }
        updatedBy {
          firstName
          lastName
        }
        parks {
          id
        }
      }
      paginationInfo {
        totalItems
        limit
        currentPage
        totalPages
        nextPage
        prevPage
        hasNextPage
        hasPrevPage
      }
    }
  }
`;

export const LOGIN = gql`
  query Login($username: String!, $password: String!) {
    login(username: $username, password: $password) {
      firstName
      lastName
      phone
      access_token
      refresh_token
      uid
      role
    }
  }
`;
