import { IProduct, IProductCreate } from './productTypes';
import { IZone } from './zoneTypes';

export enum LotLandTypeEnum {
  SFP = 'SFP',
  SDP = 'SDP',
  SSP = 'SSP',
  SDDP = 'SDDP',
}

export enum LotUrnTypeEnum {
  Single = 'Single',
  Double = 'Double',
}

export enum LotDirectionEnum {
  North = 'North',
  South = 'South',
  East = 'East',
  West = 'West',
  NorthEast = 'North-East',
  NorthWest = 'North-West',
  SouthEast = 'South-East',
  SouthWest = 'South-West',
}

export enum LotLandAreaEnum {
  Land = 'Land',
}

export enum LotUrnAreaEnum {
  Urn = 'Urn',
  Single = 'Single',
  SingleUrn = 'Single Urn',
  SingleDelux = 'Single Delux',
  Double = 'Double',
  DoubleUrn = 'Double Urn',
}

export enum LotStatusEnum {
  Blocked = 'Blocked',
  Available = 'Available',
  AvailableSelected = 'AvailableSelected',
  Attached = 'Attached',
}

export interface ILotCreate {
  id?: string | null;
  name: string | null;
  position: string | null;
  status: LotStatusEnum;
  product: IProductCreate | null;
}

export interface ILot {
  id: string;
  name: string;
  position: string;
  index: number;
  status: LotStatusEnum;
  zone: IZone;
  product: IProduct;
}
