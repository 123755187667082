import { message, Spin } from 'antd';
import { ArgsProps } from 'antd/lib/message';

type MessageProps = ArgsProps;

const openSuccessMessage = (config: MessageProps): void => {
  message.success(<span data-test="message-success">{config.content}</span>);
};

const openInfoMessage = (config: MessageProps): void => {
  message.info(<span data-test="message-info">{config.content}</span>);
};

const openWarningMessage = (config: MessageProps): void => {
  message.warning(<span data-test="message-warning">{config.content}</span>);
};

const openErrorMessage = (config: MessageProps): void => {
  message.error(<span data-test="message-error">{config.content}</span>);
};

const openLoadingMessage = (config: MessageProps): void => {
  message.loading({
    ...config,
    content: <span data-test="message-loading">{config.content}</span>,
    icon: <Spin size="small" style={{ paddingRight: 5 }} />,
  });
};

const destroyMessage = (key: string): void => {
  message.destroy(key);
};

export const messageController = {
  success: openSuccessMessage,
  info: openInfoMessage,
  warning: openWarningMessage,
  error: openErrorMessage,
  loading: openLoadingMessage,
  destroy: destroyMessage,
};
