import { IPayment, IPaymentProof } from './paymentTypes';
import { ISale } from './salesTypes';
import { IStaff } from './staffTypes';

export const paymentVerificationStageStep = ['SALES', 'LEVEL 4', 'FINANCE'];

export enum PaymentVerificationTypeEnum {
  Online = 'Online',
  Offline = 'Offline',
}

export enum PaymentVerificationStageStepStatusEnum {
  InProgress = 'InProgress',
  Waiting = 'Waiting',
  Late = 'Late',
  Invalid = 'Invalid',
  Verified = 'Verified',
  Submitted = 'Submitted',
}

export enum PaymentVerificationPurposeEnum {
  MiscellaneousSale = 'MiscellaneousSale',
  UrnSale = 'UrnSale',
  LandSale = 'LandSale',
  MonthlyInstalment = 'MonthlyInstalment',
  DefermentOutstanding = 'DefermentOutstanding',
  FullSettlement = 'FullSettlement',
  PartialSettlement = 'PartialSettlement',
  BookingFee = 'BookingFee',
  EditBooking = 'EditBooking',
  BookingExtension = 'BookingExtension',
  EditBeneficiary = 'EditBeneficiary',
  AddBeneficiary = 'AddBeneficiary',
  EditNominee = 'EditNominee',
  AddNominee = 'AddNominee',
  LotResale = 'LotResale',
}

export enum PaymentVerificationAuditTrailTypeEnum {
  SubmittedPayment = 'SubmittedPayment',
  EditedPayment = 'EditedPayment',
  InvalidatedPayment = 'InvalidatedPayment',
  VerifiedPayment = 'VerifiedPayment',
  VerificationIsLate = 'VerificationIsLate',
}

export enum PaymentVerificationAuditTrailByEnum {
  Sales = 'Sales',
  Level4 = 'Level4',
  Finance = 'Finance',
  System = 'System',
}

export enum PaymentVerificationStatus {
  PendingVerification = 'PendingVerification',
  Verified = 'Verified',
}

export interface PaymentVerificationStageStep {
  status: PaymentVerificationStageStepStatusEnum;
  updatedBy: IStaff;
  updatedAt: string;
}
export interface PaymentVerificationStage {
  id: string;
  roundCounter: number;
  round: string;
  verifyByDate: string;
  salesPerson: PaymentVerificationStageStep;
  tbd: PaymentVerificationStageStep;
  finance: PaymentVerificationStageStep;
}

export interface PaymentVerificationPaymentRejection {
  id: string;
  reason: string;
  rejectedBy: IStaff;
  rejectedAt: string;
}

export interface PaymentVerificationPayment {
  id?: string;
  status?: PaymentVerificationStageStepStatusEnum;
  paymentNo?: number;
  paymentMethod: string;
  remarks: string;
  paymentProofs: IPaymentProof[];
  verificationProofs?: IPaymentProof[];
  amount: number;
  rejection?: PaymentVerificationPaymentRejection;
  payment?: IPayment | string;
  rejectionReason?: string;
}

export interface IPaymentVerification {
  id: string;
  type: PaymentVerificationTypeEnum;
  receivedAt: string;
  purpose: PaymentVerificationPurposeEnum;
  amount: number;
  stage: PaymentVerificationStage;
  payments: PaymentVerificationPayment[];
  childPayments: IPayment[] | string[];
  sale: ISale;
  salesPerson: IStaff;
  createdAt: string;
  updatedAt: string;
}
