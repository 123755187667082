import React from 'react';
import { Col, Row } from 'antd';
import * as S from '../Header.styles';
import { HeaderContent } from './HeaderContent';

interface MobileHeaderProps {
  toggleSider: () => void;
  isSiderOpened: boolean;
  onChangeLang: (text: any) => void;
  lang: string;
  logout: () => void;
}

export const MobileHeader: React.FC<MobileHeaderProps> = ({ toggleSider, isSiderOpened, ...props }) => {
  return (
    <Row justify="space-between" align="middle">
      <S.BurgerCol>
        <S.MobileBurger onClick={toggleSider} isCross={isSiderOpened} />
      </S.BurgerCol>
      <Col>
        <Row align="middle" justify="end">
          <HeaderContent {...props} />
        </Row>
      </Col>
    </Row>
  );
};
