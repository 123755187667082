import React, { useEffect, useState, useContext } from 'react';
import { Header } from '../../../header/Header';
import MainSider from '../sider/MainSider/MainSider';
import MainContent from '../MainContent/MainContent';
import { MainHeader } from '../MainHeader/MainHeader';
import * as S from './MainLayout.styles';
import { Outlet, useLocation, useNavigate } from 'react-router-dom';
import { useResponsive } from '@app/hooks/useResponsive';
import { UserContext } from '@app/context/UserContext';
import { StaffRoleEnum } from '@app/types/staffTypes';
import { isAuthorized } from '@app/utils/utils';
import { messageController } from '@app/controllers/messageController';

const MainLayout: React.FC = () => {
  const [isTwoColumnsLayout, setIsTwoColumnsLayout] = useState(true);
  const [siderCollapsed, setSiderCollapsed] = useState(true);
  const { isDesktop } = useResponsive();
  const location = useLocation();
  const { user } = useContext(UserContext);
  const navigate = useNavigate();

  const toggleSider = () => setSiderCollapsed(!siderCollapsed);

  useEffect(() => {
    setIsTwoColumnsLayout(['/'].includes(location.pathname) && isDesktop);
  }, [location.pathname, isDesktop]);

  useEffect(() => {
    if (user === false) {
      navigate('/login');
    }

    if (user) {
      const currentPath = ROLES_BY_PATHS.find((path) => location.pathname.includes(path.path));
      if (currentPath && !isAuthorized(user, currentPath.allowed)) {
        messageController.error({ content: 'Unauthorized role' });
        navigate(-1);
      }
    }
  }, [location.pathname, user]);

  return (
    <S.LayoutMaster>
      <MainSider isCollapsed={siderCollapsed} setCollapsed={setSiderCollapsed} />
      <S.LayoutMain>
        <MainHeader isTwoColumnsLayout={isTwoColumnsLayout}>
          <Header toggleSider={toggleSider} isSiderOpened={!siderCollapsed} isTwoColumnsLayout={isTwoColumnsLayout} />
        </MainHeader>
        <MainContent id="main-content" $isTwoColumnsLayout={isTwoColumnsLayout}>
          <div>
            <Outlet />
          </div>
          {!isTwoColumnsLayout}
        </MainContent>
      </S.LayoutMain>
    </S.LayoutMaster>
  );
};

export default MainLayout;

const ROLES_BY_PATHS = [
  {
    path: '/manage-sales/create',
    allowed: [StaffRoleEnum.Manager, StaffRoleEnum.Salesperson],
  },
  {
    path: '/manage-sales/edit',
    allowed: [StaffRoleEnum.Manager, StaffRoleEnum.Salesperson],
  },
  {
    path: '/manage-miscellaneous-sales/create',
    allowed: [StaffRoleEnum.Manager, StaffRoleEnum.Salesperson],
  },
  {
    path: '/manage-miscellaneous-sales/edit',
    allowed: [StaffRoleEnum.Manager, StaffRoleEnum.Salesperson],
  },
  {
    path: '/manage-parks/edit',
    allowed: [StaffRoleEnum.Admin],
  },
  {
    path: '/manage-miscellaneous-products/create',
    allowed: [StaffRoleEnum.Admin],
  },
  {
    path: '/manage-miscellaneous-products/edit',
    allowed: [StaffRoleEnum.Admin],
  },
  {
    path: '/manage-promotions/create',
    allowed: [StaffRoleEnum.Admin, StaffRoleEnum.Manager],
  },
  {
    path: '/manage-staff',
    allowed: [
      StaffRoleEnum.Admin,
      StaffRoleEnum.SeniorSeniorManager,
      StaffRoleEnum.SeniorManager,
      StaffRoleEnum.Manager,
      StaffRoleEnum.Finance,
    ],
  },
  {
    path: '/manage-companies/edit',
    allowed: [StaffRoleEnum.Admin],
  },
  {
    path: '/manage-logics',
    allowed: [StaffRoleEnum.Admin],
  },
];
