import { IMiscProduct } from './miscProductTypes';
import { IProduct } from './productTypes';
import { IStaff } from './staffTypes';

export enum PromotionStatusEnum {
  Active = 'Active',
  Inactive = 'Inactive',
}

export enum PromotionApplicableEnum {
  Yes = 'Yes',
  No = 'No',
}

export enum PromotionTypeEnum {
  Lot = 'Lot',
  Misc = 'Misc',
}

export enum PromotionApplicablePaymentTypesEnum {
  Instant = 'Instant',
  PreNeedCash = 'PreNeedCash',
  PreNeedInstalment = 'PreNeedInstalment',
  CashPaymentPlan = 'CashPaymentPlan',
}

export enum PromotionDiscountTypeEnum {
  Percentage = 'Percentage',
  Fixed = 'Fixed',
}

export interface IPromotion {
  id: string;
  type: PromotionTypeEnum;
  title: string;
  startDate: string;
  endDate: string;
  discount: number;
  discountType: PromotionDiscountTypeEnum;
  status: PromotionStatusEnum;
  applicable: PromotionApplicableEnum;
  applicablePaymentTypes: Array<PromotionApplicablePaymentTypesEnum>;
  productsCount: number;
  products: IProduct[];
  miscProducts: IMiscProduct[];
  createdBy: IStaff;
  updatedBy: IStaff;
  createdAt: string;
  updatedAt: string;
}
