import styled from 'styled-components';
import { BREAKPOINTS, LAYOUT } from '@app/styles/themes/constants';
import { Button, Divider, Menu } from 'antd';

export const MenuSidebar = styled(Menu)`
  .ant-menu-item-selected {
    background-color: var(--primary-lighter) !important;
  }

  .ant-menu-sub.ant-menu-inline {
    background: var(--primary-subtle);
  }
`;

export const MobileStickyHeader = styled.div`
  position: sticky;
  z-index: 1;
  background-color: var(--white);
  top: -${LAYOUT.mobile.paddingVertical};
  padding-bottom: 24px;
`;

export const MobileStickyFooter = styled.div`
  position: fixed;
  width: 100%;
  left: 0;
  bottom: 0;
  z-index: 1;
  align-items: center;
  background-color: var(--white);
  border-top: 1px solid var(--neutral-lighter);
  padding: 8px 12px;
`;

export const ContainerPaymentOptions = styled.div`
  border: 1px solid #d9d9d9;
  padding: 12px;
  border-radius: 4px;
  margin-top: 8px;
`;

export const RadioGroupDivider = styled(Divider)`
  margin-top: 12px;
  margin-bottom: 12px;
  width: 100%;
`;

export const ContainerAuditInfo = styled.div`
  text-align: right;
  padding-top: 72px;
  width: 100%;
`;

export const ContainerMobileNoMargin = styled.div`
  @media (max-width: ${BREAKPOINTS.md - 0.02}px) {
    height: calc(100vh);
    margin-left: -${LAYOUT.mobile.paddingHorizontal};
    margin-right: -${LAYOUT.mobile.paddingHorizontal};
  }
`;

export const ContainerFlexSpaceBetween = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
`;

export const ButtonDrawerFooter = styled(Button)`
  width: 100%;
  height: 100%;
  border-radius: 0px !important;
`;

export const DividerNoMargin = styled(Divider)`
  margin: 0;
`;
