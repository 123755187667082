import { CheckCircleFilled, CloseCircleFilled, PaperClipOutlined } from '@ant-design/icons';
import CountdownTimer from '@app/components/common/CountdownTimer/CountdownTimer';
import { messageController } from '@app/controllers/messageController';
import { AgentStatusEnum } from '@app/types/agentTypes';
import {
  ApprovalMatrixAllowedEnum,
  ApprovalMatrixAmountTypeEnum,
  ApprovalMatrixConditionEnum,
  ApprovalMatrixTypeEnum,
  IApprovalMatrix,
} from '@app/types/approvalMatrixTypes';
import { IApproval } from '@app/types/approvalTypes';
import { CommissionStatusEnum } from '@app/types/commissionTypes';
import { ILot } from '@app/types/lotTypes';
import { IMiscProduct, MiscProductCashPaymentPlan, MiscProductStatusEnum } from '@app/types/miscProductTypes';
import { PaymentInterestStatusEnum } from '@app/types/paymentInterestTypes';
import {
  IPayment,
  IPaymentProof,
  PaymentInfoModeType,
  PaymentModeEnum,
  PaymentParentStatusEnum,
  PaymentStatusEnum,
  PaymentTypeEnum,
} from '@app/types/paymentTypes';
import { ProductAmountTypeEnum, ProductStatusEnum } from '@app/types/productTypes';
import {
  IPromotion,
  PromotionApplicableEnum,
  PromotionDiscountTypeEnum,
  PromotionStatusEnum,
} from '@app/types/promotionTypes';
import { IRefund, RefundStatusEnum, RefundTypeEnum } from '@app/types/refundTypes';
import {
  ISale,
  MiscProductsPromotion,
  MiscSaleProduct,
  PaymentOptionEnum,
  SaleBookingExtensionPeriod,
  SalePaymentTypeEnum,
  SalePurchaseTypeEnum,
  SaleStatusEnum,
  SaleTypeEnum,
} from '@app/types/salesTypes';
import { ISlot, SlotEnum, SlotTime } from '@app/types/slotTypes';
import { IStaff, StaffRoleEnum, StaffStatusEnum } from '@app/types/staffTypes';
import {
  Badge,
  BadgeProps,
  Result,
  SelectProps,
  Space,
  SpaceProps,
  TablePaginationConfig,
  Typography,
  UploadProps,
} from 'antd';
import { BaseType } from 'antd/lib/typography/Base';
import { TextProps } from 'antd/lib/typography/Text';
import { t } from 'i18next';
import moment from 'moment';
import * as GS from '../styles/GlobalStyledComponent.style';
import { IPaginatorInfo } from '@app/types/generalTypes';
import type { RcFile } from 'antd/es/upload/interface';
import { ReactNode } from 'react';
import { IPark } from '@app/types/parkTypes';
import { cachingIsEnabled } from '@app/constants/common';
import { apolloClient } from '@app/graphql/apolloService';
import { PaymentVerificationStatus } from '@app/types/paymentVerificationTypes';

const { Text } = Typography;

export const hexToRGB = (hex: string): string => {
  const r = parseInt(hex.slice(1, 3), 16),
    g = parseInt(hex.slice(3, 5), 16),
    b = parseInt(hex.slice(5, 7), 16);

  return `${r}, ${g}, ${b}`;
};

export const shadeColor = (color: string, percent: number): string => {
  let R = parseInt(color.substring(1, 3), 16);
  let G = parseInt(color.substring(3, 5), 16);
  let B = parseInt(color.substring(5, 7), 16);

  R = parseInt(((R * (100 + percent)) / 100).toString());
  G = parseInt(((G * (100 + percent)) / 100).toString());
  B = parseInt(((B * (100 + percent)) / 100).toString());

  R = R < 255 ? R : 255;
  G = G < 255 ? G : 255;
  B = B < 255 ? B : 255;

  const RR = R.toString(16).length == 1 ? '0' + R.toString(16) : R.toString(16);
  const GG = G.toString(16).length == 1 ? '0' + G.toString(16) : G.toString(16);
  const BB = B.toString(16).length == 1 ? '0' + B.toString(16) : B.toString(16);

  return '#' + RR + GG + BB;
};

export const hexToHSL = (hex: string): { h: number; s: number; l: number } => {
  const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);

  if (result) {
    let r = parseInt(result[1], 16);
    let g = parseInt(result[2], 16);
    let b = parseInt(result[3], 16);
    (r /= 255), (g /= 255), (b /= 255);
    const max = Math.max(r, g, b),
      min = Math.min(r, g, b);
    let h, s;
    const l = (max + min) / 2;
    if (max == min) {
      h = s = 0; // achromatic
    } else {
      const d = max - min;
      s = l > 0.5 ? d / (2 - max - min) : d / (max + min);
      switch (max) {
        case r:
          h = (g - b) / d + (g < b ? 6 : 0);
          break;
        case g:
          h = (b - r) / d + 2;
          break;
        case b:
          h = (r - g) / d + 4;
          break;
        default:
          h = 0;
      }
      h /= 6;
    }
    return {
      h,
      s,
      l,
    };
  } else {
    throw new Error('Non valid HEX color');
  }
};

export const getCurrency = () => {
  return 'RM';
};

export const formatDecimal = (value: string | number) => {
  return `${parseFloat(String(value || 0)).toLocaleString(undefined, {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  })}`;
};

export const formatCurrency = (value: string | number | undefined, zeroAlternate?: string): string => {
  value = String(value);
  return parseFloat(value)
    ? `${getCurrency()} ${parseFloat(value).toLocaleString(undefined, {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      })}`
    : zeroAlternate || '-';
};

export const formatCurrencyDefault = (amount: string | number | undefined) => {
  return formatCurrency(amount, `${getCurrency()} 0.00`);
};

export const isImageFile = (url: string) => {
  return ['png', 'jpg', 'jpeg']?.includes(url?.toLowerCase()?.split('.')?.pop() || '');
};

export const getFileNameFromURL = (fileURL: string) => {
  let nameFromURL = fileURL.split('/').pop();

  // Check if timestamp exist after date
  const timeStamp = nameFromURL?.split('_')?.pop();
  if (nameFromURL && timeStamp) {
    nameFromURL = nameFromURL.replace(`_${timeStamp}`, '');

    // Convert URI encoding
    if (decodeURI(nameFromURL)) {
      nameFromURL = decodeURI(nameFromURL);
    }
  }

  return nameFromURL || 'File';
};

export const getFileExtensionFromURL = (fileURL: string) => {
  if (!fileURL) return 'pdf';
  return fileURL.split('.').pop();
};

export const validateURL = (url: string) => {
  if (url.includes('https://') || url.includes('http://')) {
    return url;
  } else return `https://${url}`;
};

export const directDownload = async (fileURL: string, name?: string, isAllowedToShare?: boolean) => {
  fileURL = validateURL(fileURL);
  if (!fileURL) return messageController.error({ content: 'Download URL not found' });
  // If name parameter not assigned, set file download name given by server
  let fileDownloadName = name ?? '';
  const fileName = getFileNameFromURL(fileURL);
  if (!fileDownloadName) {
    fileDownloadName = fileName;
  }
  messageController.loading({
    content: (
      <>
        {`Downloading ${fileDownloadName}...`}{' '}
        <CloseCircleFilled
          style={{ color: 'var(--accent-red)' }}
          onClick={() => messageController.destroy(fileDownloadName)}
        />
      </>
    ),
    key: fileDownloadName,
    duration: 0,
  });

  if (navigator.share && isAllowedToShare) {
    const download = await fetch(fileURL);
    const blob = await download.blob();
    const file = new File([blob], fileDownloadName, { type: blob.type });
    messageController.destroy(fileDownloadName);
    navigator.share({
      files: [file],
    });
  } else {
    fetch(fileURL)
      ?.then((response) => response.blob())
      ?.then((blob) => {
        const anchorElement = document.createElement('a');
        anchorElement.href = URL.createObjectURL(blob);
        anchorElement.download = fileDownloadName;
        // Trigger the click event
        anchorElement.click();
        messageController.destroy(fileDownloadName);
      })
      ?.catch(() => {
        messageController.destroy(fileDownloadName);
        messageController.error({ content: 'Error fetching file URL' });
      });
  }
};

export const filterParkByCompanyCodes = (parks: IPark[], codes: string[]) => {
  return parks?.filter((park) => codes.includes(park?.company?.companyCode));
};

export const getLotDisplay = (lot: ILot) => {
  if (lot?.product?.name) {
    const sliceName = lot?.product?.name?.slice(lot?.product?.name?.indexOf('|') + 1) || '';
    return `${lot?.zone?.park?.name} • ${sliceName}`;
  } else return '';
};

export const getLotPosition = (lot: ILot | undefined, type: 'row' | 'lot' | 'rowName' | 'lotName') => {
  if (type == 'row') {
    return parseInt(lot?.position?.split(',')[0] || '') + 1;
  } else if (type == 'lot') {
    return parseInt(lot?.position?.split(',')[1] || '') + 1;
  } else if (type == 'rowName') {
    return lot?.zone?.rowNames[Number(lot?.position?.split(',')[0])];
  } else if (type == 'lotName') {
    return lot?.zone?.columnNames[Number(lot?.position?.split(',')[1])];
  }
};

export const getLotStatusBadge = (status: ProductStatusEnum) => {
  if (status == ProductStatusEnum.Unavailable) return <Badge color="var(--accent-blue)" text="Unavailable" />;
  else if (status == ProductStatusEnum.Sold) return <Badge status="error" text="Sold" />;
  else if (status == ProductStatusEnum.Booked) return <Badge status="warning" text="Booked" />;
  else if (status == ProductStatusEnum.Available) return <Badge status="success" text="Available" />;
  else return <Badge status="default" text={status}></Badge>;
};

export const getSaleStatusBadge = (sale: ISale) => {
  const status = sale?.status;
  const isPendingPaymentVerification = sale?.paymentVerificationStatus == PaymentVerificationStatus.PendingVerification;

  if (isPendingPaymentVerification) return <Badge status="processing" text="Pending verification" />;
  else if (status == SaleStatusEnum.Draft) return <Badge color="blue" text="Draft" />;
  else if (status == SaleStatusEnum.Approval) return <Badge color="#3e3e3e" text="Approval" />;
  else if (status == SaleStatusEnum.Declined) return <Badge color="pink" text="Declined" />;
  else if (status == SaleStatusEnum.Proposal) return <Badge status="warning" text="Proposal" />;
  else if (status == SaleStatusEnum.Booked) return <Badge color="purple" text="Booked" />;
  else if (status == SaleStatusEnum.Closed) return <Badge status="success" text="Closed" />;
  else if (status == SaleStatusEnum.Lost) return <Badge status="error" text="Lost" />;
  else if (status == SaleStatusEnum.Refunded) return <Badge color="cyan" text="Refunded" />;
  else if (status == SaleStatusEnum.Resold) return <Badge color="geekblue" text="Resold" />;
  else return <Badge status="default" text={status}></Badge>;
};

export const getStaffStatusBadge = (status: StaffStatusEnum) => {
  if (status == StaffStatusEnum.Active) return <Badge color="var(--accent-green)" text="Active" />;
  else if (status == StaffStatusEnum.Blocked) return <Badge color="black" text="Blocked" />;
  else if (status == StaffStatusEnum.Pending) return <Badge color="var(--accent-blue)" text="Pending" />;
};

export const getPaymentParentStatusBadge = (status: PaymentParentStatusEnum | undefined, textType?: BaseType) => {
  const statusText = textType ? <Text type={textType}>{status}</Text> : status;
  if (status == PaymentParentStatusEnum.Completed) return <Badge color="#3e3e3e" text={statusText} />;
  else if (status == PaymentParentStatusEnum.Grace) return <Badge status="default" text={statusText} />;
  else if (status == PaymentParentStatusEnum.Cancelled) return <Badge status="error" text={statusText} />;
  else if (status == PaymentParentStatusEnum.Paused) return <Badge color="var(--accent-blue)" text={statusText} />;
  else if (status == PaymentParentStatusEnum.Running || status === PaymentParentStatusEnum.Deferred)
    return <Badge status="success" text={statusText} />;
  else if (status == PaymentParentStatusEnum.Refunded) return <Badge color="cyan" text={statusText} />;
  else return statusText;
};

export const getPaymentStatusBadge = (
  status: PaymentStatusEnum | undefined,
  textType?: BaseType,
  badgeOnly?: boolean,
) => {
  let statusText;
  if (!badgeOnly) statusText = textType ? <Text type={textType}>{status}</Text> : status;
  if (status == PaymentStatusEnum.Pending || status == PaymentStatusEnum.Paused)
    return <Badge color="var(--accent-blue)" text={statusText} />;
  else if (status == PaymentStatusEnum.UnVerified || status == PaymentStatusEnum.UnPaid)
    return <Badge color="#3e3e3e" text={statusText} />;
  else if (status == PaymentStatusEnum.Due) return <Badge status="warning" text={statusText} />;
  else if (
    status == PaymentStatusEnum.Late ||
    status == PaymentStatusEnum.Failed ||
    status == PaymentStatusEnum.Rejected ||
    status == PaymentStatusEnum.Cancelled
  )
    return <Badge status="error" text={statusText} />;
  else if (status == PaymentStatusEnum.PartiallyPaid) return <Badge status="warning" text={'Partially paid'} />;
  else if (status == PaymentStatusEnum.Paid) return <Badge status="success" text={statusText} />;
  else if (status == PaymentStatusEnum.Refunded) return <Badge color="cyan" text={statusText} />;
  else return statusText;
};

export const getPaymentCommissionStatusBadge = (
  status: CommissionStatusEnum | undefined,
  textType?: BaseType,
  badgeOnly?: boolean,
) => {
  let statusText;
  if (!badgeOnly) statusText = textType ? <Text type={textType}>{status}</Text> : status;
  if (status == CommissionStatusEnum.Due) return <Badge status="warning" text={statusText} />;
  else if (status == CommissionStatusEnum.Pending) return <Badge color="var(--accent-blue)" text={statusText} />;
  else if (status == CommissionStatusEnum.Cancelled) return <Badge status="error" text={statusText} />;
  else if (status == CommissionStatusEnum.Paid) return <Badge status="success" text={statusText} />;
  else return statusText;
};

export const getPaymentInterestStatusBadge = (
  status: PaymentInterestStatusEnum | undefined,
  textType?: BaseType,
  badgeOnly?: boolean,
) => {
  let statusText;
  if (!badgeOnly) statusText = textType ? <Text type={textType}>{status}</Text> : status;
  if (status == PaymentInterestStatusEnum.Approval) return <Badge color="var(--accent-blue)" text={'Requested'} />;
  else if (status == PaymentInterestStatusEnum.Approved) return <Badge status="success" text={statusText} />;
  else if (status == PaymentInterestStatusEnum.Declined) return <Badge color="cyan" text={statusText} />;
  else if (status == PaymentInterestStatusEnum.Waived) return <Badge color="black" text={statusText} />;
  else if (status == PaymentInterestStatusEnum.PartiallyWaived) return <Badge color="black" text={'P. Waived'} />;
  else if (status == PaymentInterestStatusEnum.Due) return <Badge status="warning" text={statusText} />;
  else if (status == PaymentInterestStatusEnum.Paid) return <Badge status="success" text={statusText} />;
  else if (status == PaymentInterestStatusEnum.Withdrawn) return <Badge status="warning" text={statusText} />;
  else return statusText;
};

export const getPaymentMethodDisplay = (paymentMethod: PaymentModeEnum | undefined) => {
  if (paymentMethod == PaymentModeEnum.Online) return 'Stripe';
  else return paymentMethod;
};

export const renderPaymentProofLinks = (paymentProofs: IPaymentProof[] | undefined) => {
  return (
    <Space {...verticalSpaceProps} size={8}>
      {paymentProofs?.map((proof, index) => (
        <Typography.Link key={index} ellipsis onClick={() => directDownload(proof?.url)}>
          <Text type="secondary">
            <PaperClipOutlined />{' '}
          </Text>
          {getFileNameFromURL(proof?.url)}
        </Typography.Link>
      ))}
    </Space>
  );
};

export const getAgentStatusBadge = (status: AgentStatusEnum) => {
  if (status == AgentStatusEnum.Active) return <Badge status="success" text={status} />;
  else return <Badge color="#3E3E3E" text={status} />;
};

export const getPromotionApplicableBadge = (status: PromotionApplicableEnum) => {
  if (status == PromotionApplicableEnum.Yes) return <Badge status="success" text={status} />;
  else return <Badge status="error" text={status} />;
};

export const getPromotionStatusBadge = (status: PromotionStatusEnum) => {
  if (status == PromotionStatusEnum.Active) return <Badge status="success" text={status} />;
  else return <Badge status="error" text={status} />;
};

export const getFormattedDate = (value?: string | number | Date, format?: string) => {
  if (value) {
    value = String(value);
    return moment(parseInt(value)).format(format || 'DD/MM/YYYY') || '';
  } else return '';
};

export const getSorterValue = (order: string | undefined): undefined | 1 | -1 => {
  if (order == 'ascend') return 1;
  else if (order == 'descend') return -1;
  else return undefined;
};

export const filterSelectProps = {
  bordered: false,
  dropdownMatchSelectWidth: false,
  allowClear: true,
};

export const mobileFilterSelectProps = {
  style: { width: '100%' },
  allowClear: true,
};

export const multipleFilterSelectProps: SelectProps = {
  ...filterSelectProps,
  showArrow: true,
  showSearch: false,
  style: { minWidth: 90 },
  mode: 'multiple',
  maxTagCount: 1,
};

export const getPagination = (paginatorInfo: IPaginatorInfo | undefined): TablePaginationConfig => {
  return {
    current: paginatorInfo?.currentPage,
    pageSize: paginatorInfo?.limit,
    total: paginatorInfo?.totalItems,
    showTotal: (total: number, range: [number, number]) => `${range[0]}-${range[1]} of ${total} items`,
    showSizeChanger: true,
    position: ['bottomRight'],
  };
};

export const verticalSpaceProps: SpaceProps = {
  className: 'width-100',
  direction: 'vertical',
};

export const acceptedUploadFileFormat: UploadProps = { accept: 'application/pdf, image/png, image/jpeg' };

export const uploadProps: UploadProps = {
  beforeUpload: () => false,
  multiple: false,
  maxCount: 1,
  showUploadList: {
    showRemoveIcon: false,
  },
  ...acceptedUploadFileFormat,
};

export const multipleUploadProps: UploadProps = {
  beforeUpload: () => false,
  showUploadList: {
    showRemoveIcon: false,
  },
  ...acceptedUploadFileFormat,
  multiple: true,
};

export const displayAmount = (value: number) => {
  return (
    <GS.ContainerFlexSpaceBetween>
      {getCurrency()}
      <div>{formatDecimal(value)}</div>
    </GS.ContainerFlexSpaceBetween>
  );
};

export const calculateProductCosts = (
  licensingFee: number,
  continuityFee: number,
  aluminiumEmboss: number,
  backwallWalkway: number,
  tombPackagePrice: number,
  offerTombPackage: boolean,
  preNeedCashDiscount: number,
  preNeedCashDiscountType: ProductAmountTypeEnum | undefined,
  preNeedInstalmentDiscount: number,
  preNeedInstalmentDiscountType: ProductAmountTypeEnum | undefined,
) => {
  const getDiscount = (type: ProductAmountTypeEnum | undefined, amount: number) => {
    if (type == ProductAmountTypeEnum.Fixed) return amount;
    else if (type == ProductAmountTypeEnum.Percentage) return (licensingFee * amount) / 100;
    else return 0;
  };

  let [instantCase, preNeedFullPayment, preNeedInstalment] = [
    licensingFee + continuityFee + aluminiumEmboss + backwallWalkway,
    0,
    0,
  ];

  preNeedFullPayment =
    licensingFee -
    getDiscount(preNeedCashDiscountType, preNeedCashDiscount) +
    (continuityFee + aluminiumEmboss + backwallWalkway);
  preNeedInstalment =
    licensingFee -
    getDiscount(preNeedInstalmentDiscountType, preNeedInstalmentDiscount) +
    (continuityFee + aluminiumEmboss + backwallWalkway);

  if (offerTombPackage) {
    instantCase += tombPackagePrice;
    preNeedFullPayment += tombPackagePrice;
    preNeedInstalment += tombPackagePrice;
  }

  return {
    costInstantCase: Number(instantCase.toFixed(2)),
    costPreNeedFullPayment: Number(preNeedFullPayment.toFixed(2)),
    costPreNeedInstalment: Number(preNeedInstalment.toFixed(2)),
  };
};

export const getUsernameDisplay = (
  user: { firstName: string | undefined; lastName: string | undefined } | undefined,
) => {
  return user?.firstName || user?.lastName ? `${user?.lastName || ''} ${user?.firstName || ''}` : '';
};

export const getPromotionDiscountDisplay = (promotion: IPromotion) => {
  if (promotion?.discountType == PromotionDiscountTypeEnum.Percentage) return `${promotion.discount}%`;
  else if (promotion?.discountType == PromotionDiscountTypeEnum.Fixed) return formatCurrency(promotion.discount);
};

export const isAuthorized = (user: IStaff, allowedRoles?: StaffRoleEnum[]): boolean => {
  if (user && user?.role) {
    if (allowedRoles && allowedRoles?.includes(user?.role)) return true;
    if (!allowedRoles) return true;
  }
  return false;
};

// Map historical instalment data
export const mapInstalmentDuration = (sale: ISale) => {
  if (sale?.paymentType == SalePaymentTypeEnum.Instalment || sale?.paymentType == SalePaymentTypeEnum.CashPaymentPlan) {
    if (sale?.years) {
      return sale?.years * 12;
    }
    if (sale?.months) {
      return sale?.months;
    }
  }
  return 0;
};

export const getInstalmentDurationDisplay = (durationInMonth: number, sale?: ISale, showMonthlyPayment?: boolean) => {
  let monthlyPayment = '';
  if (sale && showMonthlyPayment) {
    monthlyPayment = ` (${formatCurrencyDefault(
      Math.ceil((sale?.finalCost - sale?.bookingAmount - sale?.downPayment) / durationInMonth),
    )} p/m)`;
  }
  if (durationInMonth < 12) {
    return `${durationInMonth} months` + monthlyPayment;
  } else {
    const durationInYear = durationInMonth / 12;
    return `${durationInYear} year${durationInYear > 1 ? 's' : ''}` + monthlyPayment;
  }
};

export const getStaffRoleName = (role: StaffRoleEnum | undefined, capitalizeFirstLetter = true) => {
  let roleName: string | undefined = role;
  switch (role) {
    case undefined:
      roleName = '';
      break;
    case StaffRoleEnum.Salesperson:
      roleName = 'Level 1';
      break;
    case StaffRoleEnum.Manager:
      roleName = 'Level 2';
      break;
    case StaffRoleEnum.SeniorManager:
      roleName = 'Level 3';
      break;
    case StaffRoleEnum.SeniorSeniorManager:
      roleName = 'Level 4';
      break;
    default:
      roleName = role;
  }
  if (capitalizeFirstLetter) return roleName?.charAt(0)?.toUpperCase() + roleName?.slice(1);
  else return roleName;
};

export const calculateBookingExtensionPeriod = (sale: ISale | undefined, matrixNo: number) => {
  let duration;
  let endDate = '';
  const saleExpirationDate = Number(sale?.expirationDate);

  if (matrixNo == 1) {
    duration = SaleBookingExtensionPeriod.First;
    endDate = moment(saleExpirationDate).startOf('day').add(duration, 'days').endOf('day').format('DD MMM YYYY');
  } else if (matrixNo == 2) {
    duration = SaleBookingExtensionPeriod.Second;
    endDate = moment(saleExpirationDate).startOf('day').add(duration, 'days').endOf('day').format('DD MMM YYYY');
  } else if (matrixNo == 3) {
    duration = SaleBookingExtensionPeriod.Third;
    endDate = moment(saleExpirationDate).startOf('day').add(duration, 'days').endOf('day').format('DD MMM YYYY');
  } else if (matrixNo == 4) {
    duration = 'Infinite';
    endDate = '∞';
    return 'Infinite booking extension';
  }

  return `${duration} days booking extension until ${endDate}`;
};

export const calculateFee = (
  sale: Partial<ISale> | undefined,
  approvalMatrix: IApprovalMatrix | undefined,
  type: 'adminFee' | 'amount',
) => {
  let licensingFee = sale?.licensingFee;
  if (sale?.type == SaleTypeEnum.MiscSale) {
    licensingFee = sale?.finalCost;
  }
  if (licensingFee) {
    if (type == 'adminFee') {
      if (approvalMatrix?.adminFeeType == ApprovalMatrixAmountTypeEnum.Fixed) return approvalMatrix?.adminFee;
      else if (approvalMatrix?.adminFeeType == ApprovalMatrixAmountTypeEnum.Percentage)
        return (approvalMatrix?.adminFee / 100) * licensingFee;
    } else if (type == 'amount') {
      if (approvalMatrix?.amountType == ApprovalMatrixAmountTypeEnum.Fixed) return approvalMatrix?.amount;
      else if (approvalMatrix?.amountType == ApprovalMatrixAmountTypeEnum.Percentage)
        return (approvalMatrix?.amount / 100) * licensingFee;
    }
  }
  return 0;
};

export const getApprovalMatrixConditionSymbol = (condition: string) => {
  switch (condition) {
    case ApprovalMatrixConditionEnum.EqualTo:
      return '=';
    case ApprovalMatrixConditionEnum.GreaterThanOrEqualTo:
      return '≥';
    case ApprovalMatrixConditionEnum.LessThanOrEqualTo:
      return '≤';
    case ApprovalMatrixConditionEnum.BetweenOrEqualTo:
      return '>=<';
    default:
      return '';
  }
};

export const getPaymentInfo = (
  paymentInfoMode: PaymentInfoModeType,
  saleData: ISale | undefined,
  paymentData: IPayment | undefined,
  successText?: ReactNode,
  renderReceipt = true,
  noPaymentValidation = false,
) => {
  if (paymentInfoMode == 'success' && saleData?.payment?.status !== PaymentStatusEnum.Failed) {
    let paymentSuccessContent;

    if (noPaymentValidation || saleData?.payment?.status || paymentData?.status) {
      paymentSuccessContent = (
        <>
          {successText && <Text type="secondary">{successText}</Text>}
          {renderReceipt && (
            <Space {...verticalSpaceProps}>
              {saleData?.bookingFormUrl && (
                <Typography.Link
                  underline
                  onClick={() => {
                    directDownload(saleData?.bookingFormUrl, 'Booking form.pdf');
                  }}
                >
                  {t('Download booking form')}
                </Typography.Link>
              )}
              {paymentData?.receipt && (
                <Typography.Link
                  underline
                  onClick={() => {
                    directDownload(paymentData?.receipt);
                  }}
                >
                  {t('Download receipt')}
                </Typography.Link>
              )}
            </Space>
          )}
        </>
      );
    } else {
      paymentSuccessContent = (
        <Space {...verticalSpaceProps}>
          <Text type="secondary">{t('The receipt will be available once the server has processed the payment.')}</Text>
          <Typography.Link underline onClick={() => window.location.reload()}>
            {t('Refresh')}
          </Typography.Link>
        </Space>
      );
    }

    return (
      <Space {...verticalSpaceProps}>
        <CheckCircleFilled style={{ color: 'var(--accent-green)', fontSize: 32 }} />
        {paymentSuccessContent}
      </Space>
    );
  } else if (paymentInfoMode == 'cancel') {
    return <Result status="warning" title={t('Payment was cancelled.')} />;
  } else if (
    paymentInfoMode == 'fail' ||
    (paymentInfoMode == 'success' && saleData?.payment?.status == PaymentStatusEnum.Failed)
  ) {
    return <Result status="error" title={t('Payment was failed.')} />;
  }
};

export const getRefundStatusBadge = (status: RefundStatusEnum, textType?: BaseType) => {
  const statusText = textType ? <Text type={textType}>{status}</Text> : status;
  if (status == RefundStatusEnum.Completed) return <Badge color="#3e3e3e" text={statusText} />;
  else if (status == RefundStatusEnum.Approval) return <Badge status="error" text={statusText} />;
  else if (status == RefundStatusEnum.InProcess) return <Badge status="warning" text={'In process'} />;
  else return <Badge color="var(--accent-blue)" text={statusText} />;
};

export const refundIsPending = (refund: IRefund | undefined) => {
  if (
    refund?.status == RefundStatusEnum.Approval ||
    refund?.status == RefundStatusEnum.Outstanding ||
    refund?.status == RefundStatusEnum.InProcess
  )
    return true;
  else return false;
};

export const getRefundMenu = (type: RefundTypeEnum, refund: IRefund | undefined) => {
  let label = '';
  const approvalStage = refund?.status == RefundStatusEnum.Approval ? 'approval' : 'refund';
  if (type == RefundTypeEnum.Booking) {
    if (refundIsPending(refund)) label = `Pending ${approvalStage} (Cancel booking)`;
    else label = 'Cancel booking';
  } else if (type == RefundTypeEnum.Sale) {
    if (refundIsPending(refund)) label = `Pending ${approvalStage} (Withdraw)`;
    else label = 'Withdraw';
  }

  return label;
};

export const checkRefundEligibility = (
  type: 'Booking' | 'Sale',
  user: IStaff,
  approvalMatrix: IApprovalMatrix | undefined,
  sale: ISale | undefined,
) => {
  if (
    isAuthorized(user, [StaffRoleEnum.Manager, StaffRoleEnum.Salesperson]) &&
    approvalMatrix?.allowed == ApprovalMatrixAllowedEnum.Yes &&
    sale &&
    (!sale?.refund ||
      sale?.refund?.status == RefundStatusEnum.Declined ||
      sale?.refund?.status == RefundStatusEnum.Withdrawn)
  ) {
    const isWithinGracePeriod = Number(parseInt(sale?.gracePeriod || '') - parseInt(moment().format('x'))) > 0;

    if (type == 'Booking') {
      if (approvalMatrix?.type == ApprovalMatrixTypeEnum.RefundBooking && sale?.status == SaleStatusEnum.Booked) {
        return true;
      }
    } else if (type == 'Sale') {
      if (
        ((sale?.type == SaleTypeEnum.LotSale && approvalMatrix?.type == ApprovalMatrixTypeEnum.SaleWithdrawal) ||
          (sale?.type == SaleTypeEnum.MiscSale && approvalMatrix?.type == ApprovalMatrixTypeEnum.MiscSaleWithdrawal)) &&
        sale?.status == SaleStatusEnum.Closed &&
        (sale?.paymentType == SalePaymentTypeEnum.Cash || sale?.paymentType == SalePaymentTypeEnum.Instalment) &&
        isWithinGracePeriod
      ) {
        return true;
      }
    }
  }

  return false;
};

export const getApprovalTypeDisplay = (approvalType: ApprovalMatrixTypeEnum | string | undefined) => {
  let type = approvalType || '';
  switch (approvalType) {
    case ApprovalMatrixTypeEnum.RefundBooking: {
      type = 'Refund booking';
      break;
    }
    case ApprovalMatrixTypeEnum.SaleWithdrawal: {
      type = 'Sale withdrawal';
      break;
    }
    case ApprovalMatrixTypeEnum.MiscSaleWithdrawal: {
      type = 'Misc sale withdrawal';
      break;
    }
    case ApprovalMatrixTypeEnum.SalesDiscount: {
      type = 'Sale discount';
      break;
    }
    case ApprovalMatrixTypeEnum.BookingExtension: {
      type = 'Booking extension';
      break;
    }
    case ApprovalMatrixTypeEnum.EditBooking: {
      type = 'Edit booking';
      break;
    }
    case ApprovalMatrixTypeEnum.AddNominee: {
      type = 'Add nominee';
      break;
    }
    case ApprovalMatrixTypeEnum.AddBeneficiary: {
      type = 'Add beneficiary';
      break;
    }
    case ApprovalMatrixTypeEnum.EditNominee: {
      type = 'Edit nominee';
      break;
    }
    case ApprovalMatrixTypeEnum.EditBeneficiary: {
      type = 'Edit beneficiary';
      break;
    }
    case ApprovalMatrixTypeEnum.WaiveLatePaymentInterest: {
      type = 'Waive interest';
      break;
    }
  }
  return type;
};

export const getSalePaymentDisplay = (paymentType: SalePaymentTypeEnum | undefined) => {
  let payment = paymentType || '';
  switch (paymentType) {
    case SalePaymentTypeEnum.Instant: {
      payment = 'Immediate';
      break;
    }
    case SalePaymentTypeEnum.Cash: {
      payment = 'Pre-planning (Upfront)';
      break;
    }
    case SalePaymentTypeEnum.Instalment: {
      payment = 'Pre-planning (Instalment)';
      break;
    }
    case SalePaymentTypeEnum.CashPaymentPlan: {
      payment = 'Cash payment plan';
      break;
    }
    case SalePaymentTypeEnum.BookingExtension: {
      payment = 'Booking extension';
      break;
    }
    case SalePaymentTypeEnum.EditBooking: {
      payment = 'Edit booking';
      break;
    }
  }
  return payment;
};

export const getPaymentPaymentTypeDisplay = (paymentType: PaymentTypeEnum | undefined) => {
  let payment = paymentType || '';
  switch (paymentType) {
    case PaymentTypeEnum.Instant: {
      payment = getSalePaymentDisplay(SalePaymentTypeEnum.Instant);
      break;
    }
    case PaymentTypeEnum.Cash: {
      payment = getSalePaymentDisplay(SalePaymentTypeEnum.Cash);
      break;
    }
    case PaymentTypeEnum.Instalment: {
      payment = getSalePaymentDisplay(SalePaymentTypeEnum.Instalment);
      break;
    }
    case PaymentTypeEnum.BookingExtension: {
      payment = 'Booking extension';
      break;
    }
    case PaymentTypeEnum.EditBooking: {
      payment = 'Edit booking';
      break;
    }
    case PaymentTypeEnum.AddNominee: {
      payment = 'Add nominee';
      break;
    }
    case PaymentTypeEnum.AddBeneficiary: {
      payment = 'Add beneficiary';
      break;
    }
    case PaymentTypeEnum.EditNominee: {
      payment = 'Edit nominee';
      break;
    }
    case PaymentTypeEnum.EditBeneficiary: {
      payment = 'Edit beneficiary';
      break;
    }
    case PaymentTypeEnum.LotResale: {
      payment = 'Lot resale';
      break;
    }
  }
  return payment;
};

export const getMiscSaleProductDisplay = (sale: ISale, isMobile?: boolean) => {
  const miscProducts = sale?.products;
  const title = `${miscProducts[0]?.product?.product?.name} • ${miscProducts[0]?.product?.type?.name}`;
  if (miscProducts?.length == 1)
    if (isMobile) return title;
    else
      return (
        <Space direction="vertical" size={0}>
          <Text type="secondary" style={{ fontSize: 12, width: 200 }} ellipsis>
            {title}
          </Text>
          <Text style={{ width: 200 }} ellipsis>
            {miscProducts[0]?.product?.description}
          </Text>
        </Space>
      );
  else if (miscProducts?.length > 1) return 'Multiple products';
};

export const getMiscSaleProductsBreakdown = (
  product: MiscSaleProduct | undefined,
  promotion?: MiscProductsPromotion,
  textProps?: TextProps,
) => {
  const deadline = parseInt(promotion?.promotion?.endDate || '') - parseInt(moment().format('x'));

  if (product)
    return (
      <Space size={8}>
        <div>
          <Text {...textProps}>{product?.quantity}</Text>
          <Text type="secondary">&nbsp;&nbsp;&nbsp;&#215;&nbsp;&nbsp;&nbsp;</Text>
        </div>
        <Space {...verticalSpaceProps} size={0}>
          <Text type="secondary" style={{ fontSize: 12 }} {...textProps}>
            {product?.product?.product?.name}
            {' • '}
            {product?.product?.type?.name}
          </Text>
          <Text {...textProps}>{product?.product?.description}</Text>
          {promotion && (
            <div>
              <Text type="secondary">
                {formatCurrencyDefault(promotion?.promotionAmount)} {t('Off')}
              </Text>{' '}
              {promotion?.promotion?.endDate ? (
                <Text type="danger">
                  {' • '}
                  {t('Promo ends in')}{' '}
                  <Text type="danger" strong>
                    <CountdownTimer countdownDuration={deadline} />
                  </Text>
                </Text>
              ) : (
                <></>
              )}
            </div>
          )}
        </Space>
      </Space>
    );
};

export const getMiscProductName = (product: IMiscProduct) => {
  return (
    <Space direction="vertical" size={0}>
      <Text type="secondary" style={{ fontSize: 12, width: 200 }} ellipsis>
        {product?.product?.name} • {product?.type?.name}
      </Text>
      <Text style={{ width: 200 }} ellipsis>
        {product?.description || ' '}
      </Text>
    </Space>
  );
};

export const getMiscProductStatusBadge = (status: MiscProductStatusEnum) => {
  if (status == MiscProductStatusEnum.Unavailable) return <Badge status="error" text="Unavailable" />;
  else if (status == MiscProductStatusEnum.Available) return <Badge status="success" text="Available" />;
  else return <Badge status="default" text={status}></Badge>;
};

export const getMiscProductCashPaymentPlanBreakdown = (costInstant: number | undefined, isPaymentOptions?: boolean) => {
  const getInstalmentRate = (label: string, monthlyRate: MiscProductCashPaymentPlan) => {
    const breakdownLabel = (
      <>
        {label} {t('instalment')} ({monthlyRate}%)
      </>
    );
    const breakdownAmount = (
      <Text type="secondary">{formatCurrency((monthlyRate / 100) * Number(costInstant), `${getCurrency()} 0.00`)}</Text>
    );

    return isPaymentOptions ? (
      <GS.ContainerFlexSpaceBetween>
        <div>{breakdownLabel}</div>
        {breakdownAmount}
      </GS.ContainerFlexSpaceBetween>
    ) : (
      <div>
        {breakdownLabel}
        {' - '}
        {breakdownAmount}
      </div>
    );
  };

  return (
    <Space {...verticalSpaceProps} size={0}>
      {getInstalmentRate('1st', MiscProductCashPaymentPlan.FirstMonth)}
      {getInstalmentRate('2nd', MiscProductCashPaymentPlan.SecondMonth)}
      {getInstalmentRate('3rd', MiscProductCashPaymentPlan.ThirdMonth)}
      {getInstalmentRate('4th', MiscProductCashPaymentPlan.FourthMonth)}
    </Space>
  );
};

export const pathContains = (paths: string[]) => {
  let containPath = false;
  for (let i = 0; i < paths.length; i++) {
    if (window?.location?.pathname?.includes(paths[i])) {
      containPath = true;
    }
  }
  return containPath;
};

export const getStepDescription = (stepIndex: number, currentStep: number) => {
  if (currentStep == stepIndex) return 'In progress';
  if (currentStep < stepIndex) return 'Waiting';
  if (currentStep > stepIndex) return 'Finished';
};

export const mapPaymentOption = (
  paymentOption: PaymentOptionEnum | undefined,
  field: 'paymentType' | 'purchaseType',
) => {
  let purchaseType, paymentType;
  switch (paymentOption) {
    case PaymentOptionEnum.InstantCash: {
      purchaseType = SalePurchaseTypeEnum.Instant;
      paymentType = SalePaymentTypeEnum.Instant;
      break;
    }
    case PaymentOptionEnum.CashPaymentPlan: {
      purchaseType = SalePurchaseTypeEnum.PreNeed;
      paymentType = SalePaymentTypeEnum.CashPaymentPlan;
      break;
    }
    case PaymentOptionEnum.PreNeedCashFull: {
      purchaseType = SalePurchaseTypeEnum.PreNeed;
      paymentType = SalePaymentTypeEnum.Cash;
      break;
    }
    case PaymentOptionEnum.PreNeedInstalment: {
      purchaseType = SalePurchaseTypeEnum.PreNeed;
      paymentType = SalePaymentTypeEnum.Instalment;
      break;
    }
  }
  if (field == 'purchaseType') return purchaseType;
  if (field == 'paymentType') return paymentType;
};

export const mapToPaymentOption = (paymentType: SalePaymentTypeEnum) => {
  let paymentOption;

  switch (paymentType) {
    case SalePaymentTypeEnum.Instant: {
      paymentOption = PaymentOptionEnum.InstantCash;
      break;
    }
    case SalePaymentTypeEnum.Cash: {
      paymentOption = PaymentOptionEnum.PreNeedCashFull;
      break;
    }
    case SalePaymentTypeEnum.CashPaymentPlan: {
      paymentOption = PaymentOptionEnum.CashPaymentPlan;
      break;
    }
    case SalePaymentTypeEnum.Instalment: {
      paymentOption = PaymentOptionEnum.PreNeedInstalment;
      break;
    }
  }
  return paymentOption;
};

export const getEditSaleStatusBadge = (status: SaleStatusEnum | undefined) => {
  let badgeProps: BadgeProps = {};
  switch (status) {
    case SaleStatusEnum.Proposal:
      badgeProps = {
        status: 'warning',
      };
      break;
    case SaleStatusEnum.Draft:
      badgeProps = {
        color: 'blue',
      };
      break;
    case SaleStatusEnum.Declined:
      badgeProps = {
        color: 'pink',
      };
      break;
    case SaleStatusEnum.Lost:
      badgeProps = {
        status: 'error',
      };
      break;
  }
  return (
    <Space size={4}>
      <Text>{t(status || '')}</Text> <Badge {...badgeProps} />
    </Space>
  );
};

export const capitalizeFirstLetter = (letter: string | undefined) => {
  if (letter) return letter.charAt(0).toUpperCase() + letter.slice(1);
};

export const getSlotDisplay = (slot: ISlot, displaySlot = false, auditTrailView = false) => {
  if (slot) {
    const date = moment()
      .date(slot.day)
      .month(slot.month - 1)
      .year(slot.year)
      .format('DD MMMM YYYY');

    if (displaySlot) {
      return slot.slot === SlotEnum.AM ? `Morning slot: ${SlotTime.AM}` : `Afternoon slot: ${SlotTime.PM}`;
    }
    if (auditTrailView) {
      return slot.slot === SlotEnum.AM ? `${date} • Morning slot` : `${date} • Afternoon slot`;
    }

    return date;
  }
  return '';
};

export const generateAuditInfoFromSale = (sale: ISale | undefined) => {
  return {
    createdBy: sale?.createdBy?.staff,
    updatedBy: sale?.updatedBy?.staff,
    createdAt: sale?.createdBy?.date,
    updatedAt: sale?.updatedBy?.date,
  };
};

export const getStaffStatus = (staff: IStaff) => {
  const isSuspended = staff.isSuspended || false;
  return isSuspended ? StaffStatusEnum.Blocked : staff.uid ? StaffStatusEnum.Active : StaffStatusEnum.Pending;
};

export const getUrlParams = (query: string) => {
  const urlParams = new URLSearchParams(window.location.search);
  return urlParams?.get(query);
};

export const getSalePath = (sale: ISale | undefined) => {
  if (sale)
    return `${
      sale.status == SaleStatusEnum.Draft ||
      sale.status == SaleStatusEnum.Declined ||
      sale.status == SaleStatusEnum.Lost
        ? 'edit'
        : 'process'
    }/${sale.id}`;
};

export const getViewSalePermission = (sale: ISale | undefined, user: IStaff) => {
  if (
    sale?.status == SaleStatusEnum.Draft ||
    sale?.status == SaleStatusEnum.Declined ||
    sale?.status == SaleStatusEnum.Lost
  ) {
    if (isAuthorized(user, [StaffRoleEnum.Manager, StaffRoleEnum.Salesperson])) return true;
    else return false;
  } else return true;
};

export const getInstalmentMonthlyOption = (paymentBreakdown: any) => {
  const instalmentMonthlyOptions_year = [];
  const instalmentMonthlyOptions_month = [];

  for (let i = 0; i < paymentBreakdown?.preNeed?.instalment?.entries?.length; i++) {
    const option = {
      value: paymentBreakdown?.preNeed?.instalment?.entries[i]?.months,
      label: getInstalmentDurationDisplay(paymentBreakdown?.preNeed?.instalment?.entries[i]?.months),
      price: paymentBreakdown?.preNeed?.instalment?.entries[i]?.perMonth,
    };
    if (paymentBreakdown?.preNeed?.instalment?.entries[i]?.months >= 12) {
      instalmentMonthlyOptions_year.push(option);
    } else {
      instalmentMonthlyOptions_month.push(option);
    }
  }

  return instalmentMonthlyOptions_year?.concat(instalmentMonthlyOptions_month);
};

export const saleIsClosed = (sale: ISale | undefined) => {
  if (sale?.status == SaleStatusEnum.Closed || sale?.status == SaleStatusEnum.Resold) return true;
  else return false;
};

export const displaySaleFinalCost = (sale: ISale | undefined) => {
  if (sale?.finalCost) return Number(sale?.finalCost) - Number(sale?.bookingAmount);
  else return 0;
};

export const getCumulativePayment = (payments: IPayment[] | undefined) => {
  return payments?.reduce((prev, curr) => prev + curr?.amount, 0);
};

export const getApprovalPath = (approval: IApproval | undefined) => {
  if (approval?.type == ApprovalMatrixTypeEnum.WaiveLatePaymentInterest) {
    return `/manage-payments/view/${approval?.approvalExtension?.paymentInterestParentId}?approvalMode=true&approvalId=${approval?.id}`;
  } else return `/manage-approvals/view/${approval?.id}`;
};

export const filterApprovalMatrixes = (
  approvalMatrixes: IApprovalMatrix[],
  type: ApprovalMatrixTypeEnum,
): IApprovalMatrix | undefined => {
  if (approvalMatrixes?.length) {
    return approvalMatrixes?.find((matrix) => matrix?.type == type);
  }
};

export const getApprovalProductsDisplay = (data: IRefund | IApproval) => {
  if (data?.sale?.type == SaleTypeEnum.LotSale) {
    return data?.sale?.lot?.name;
  } else if (data?.sale?.type == SaleTypeEnum.MiscSale) {
    return data?.sale?.products?.length + ' ' + t(`misc. product${data?.sale?.products?.length > 1 ? 's' : ''}`);
  }
};

export const displayEnumString = (_enum: string | undefined) => {
  const _string = _enum?.replace(/([a-z])([A-Z])/g, '$1 $2');
  return _string?.length ? _string?.charAt(0).toUpperCase() + _string?.slice(1)?.toLowerCase() : '';
};

export const getBase64 = (img: RcFile, callback: (url: string) => void) => {
  const reader = new FileReader();
  reader.addEventListener('load', () => callback(reader.result as string));
  reader.readAsDataURL(img);
};

export const showLoadingMessage = (show: boolean) => {
  const loaderKey = 'loader';
  if (show) {
    messageController.loading({
      content: t('Loading...'),
      key: loaderKey,
      duration: 0,
    });
  } else {
    messageController.destroy(loaderKey);
  }
};

export const clearCache = () => {
  cachingIsEnabled && apolloClient.clearStore();
};

export const regexFormatter = (text: string) => {
  return text.replace(/[-[\]{}()*+?.,\\^$|#\s]/g, '\\$&');
};
