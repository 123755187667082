import React, { useState, useEffect, useContext } from 'react';
import { DesktopHeader } from './layouts/DesktopHeader';
import { MobileHeader } from './layouts/MobileHeader';
import { useResponsive } from '@app/hooks/useResponsive';
import { useTranslation } from 'react-i18next';
import { UserContext } from '@app/context/UserContext';

interface HeaderProps {
  toggleSider: () => void;
  isSiderOpened: boolean;
  isTwoColumnsLayout: boolean;
}

export const Header: React.FC<HeaderProps> = ({ toggleSider, isSiderOpened, isTwoColumnsLayout }) => {
  const { i18n } = useTranslation();
  const { isTablet } = useResponsive();
  const [lang, setLang] = useState(i18n.language);
  const { logout } = useContext(UserContext);

  useEffect(() => {
    const savedLang = localStorage.getItem('lang');
    if (savedLang) {
      onChangeLang(savedLang);
    }
  }, []);

  const onChangeLang = (lang: any) => {
    setLang(lang);
    i18n.changeLanguage(lang);
    localStorage.setItem('lang', lang);
  };

  return isTablet ? (
    <DesktopHeader isTwoColumnsLayout={isTwoColumnsLayout} lang={lang} onChangeLang={onChangeLang} logout={logout} />
  ) : (
    <MobileHeader
      toggleSider={toggleSider}
      isSiderOpened={isSiderOpened}
      lang={lang}
      onChangeLang={onChangeLang}
      logout={logout}
    />
  );
};
