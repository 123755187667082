import { createGlobalStyle } from 'styled-components';
import { resetCss } from './resetCss';
import {
  lightThemeVariables,
  darkThemeVariables,
  commonThemeVariables,
  antOverrideCssVariables,
} from './themes/themeVariables';

export default createGlobalStyle`

  ${resetCss}

  [data-theme='light'],
  :root {
    ${lightThemeVariables}
  }

  [data-theme='dark'] {
    ${darkThemeVariables}
  }

  :root {
    ${commonThemeVariables};
    ${antOverrideCssVariables};
  } 

  [data-no-transition] * {
    transition: none !important;
  }

  .ant-btn{
    font-weight: 500;
    size: 14px;
    line-height: 22px;
    border-radius: 2px;
    padding: 4px 15px 4px 15px;
  }

  //ant <Upload> button
  div.ant-upload.ant-upload-select.ant-upload-select-text,
  div.ant-upload.ant-upload-select.ant-upload-select-picture
  {
    display:block;
    span > button{
      width: 100%;
    }
  }
  div.ant-upload-list-item-info .ant-upload-span {
    margin-top: 16px;
    a.ant-upload-list-item-name {
      color: var(--primary-main);
    }
  }

  .signature-upload span.ant-upload-span {
    margin-top: 0;
  }

  .ant-card{
    height: 100%;
  }

  .link-ghost-main{
    color: var(--primary-main) !important;
    font-weight: 500;
    font-size: 14px;
    &:hover,:active {
      color: #cf804d;
    }
    &:focus {
      color: var(--primary-darker);
    }
  }

  .txt-primary-main{
    color: var(--primary-main);
    font-weight: 500;
  }

  .step-circle{
    display: flex;
    justify-content: center;
    align-items: center;
    width: 22px;
    height: 22px;
    border: 1.25px solid rgba(0, 0, 0, 0.45);
    border-radius: 100px;
  }

  .site-drawer-render-in-current-wrapper {
    position: relative;
    height: calc(90vh - 30px);
    overflow: hidden;
    text-align: center;
    border-radius: 0 0 0 24px;

    .ant-drawer-footer{
      padding: 0;
    }
  }

  .zone-layout {
    th.ant-table-cell{
      background: white;
      text-align: center;
    }

    .ant-table-tbody > tr.ant-table-row:hover > td {
      background: white !important;
    } 

    td{
      text-align: center;
    }

    .ant-table-tbody>tr>td, .ant-table-thead>tr>th {
      border: none;
    }

    tr:hover td{
      background: transparent !important;
    }

    .ant-table-thead>tr>th:before {
      background-color: transparent !important;
    }

    .ant-table-cell-scrollbar:not([rowspan]){
      box-shadow: none;
    }

    &.mobile{
      th:nth-child(2), td:nth-child(2)  {
        border-right: 1px solid var(--neutral-lighter);
      }

      tr > th, tr:last-child > td {
        border-bottom: 1px solid var(--neutral-lighter);
      }
    }
  }

  .ant-modal{
    top: 3%;
  }

  .ant-modal-body{
    max-height: calc(90vh - 30px);
    overflow: hidden;
  }

  .zone-step2 .ant-modal-body{
    height: calc(90vh - 30px);
    padding-right: 0px;
    padding-bottom: 0px;
  }

  .zone-step3 .ant-modal-body {
    height: calc(90vh - 30px);
    padding: 0;
  }

  .ant-input-number, .ant-picker {
    width: 100%;
  }

  span.ant-input-group-addon > button {
    padding:0;
  }

  #lot {
    .ant-select-selector{
      border-radius: 0 2px 2px 0;
    }
  }

  .mobile-header {
    font-weight: 700;
    font-size: 16px;
    line-height: 24px;
  }

  .sigCanvas {
    width: 100%;
    height: calc(35vh);
    border: 1px solid #d9d9d9;
  }

  .mobile-sigCanvas{
    .sigCanvas {
      width: 100%;
      height: calc(60vh);
      border: 1px solid #d9d9d9;
    }
  }

  .actionButton {
    text-align: left;
    width: 100%;
  }

  .hideCheckboxSelection {
    th.ant-table-cell.ant-table-selection-column > div, td.ant-table-cell.ant-table-selection-column{
      label > span{
        display: none;
      }
    }   
  }

  .mobile-card {
    width: 100%;
    margin-bottom: 16px;
  }

  .width-100 {
    width: 100%;
  }

  .height-100 {
    height: 100%;
  }

  .site-input-split{
    width: 30px !important;
    border-left: 0;
    border-right: 0;
    pointer-events: none;
    &.mobile{
      width: 10% !important;
    }
    

  }

  label .light {
    color: rgba(0, 0, 0, 0.45);
    margin-left: 5px;
  }

  #paymentOptions {
    label, label > span:nth-child(2) {
      width: 100%;
    }
    width: 100%;
  }

  #tableProductQuantity {
    div > div > table > thead > tr > th:nth-child(1){
      text-align: right;
    }
  }

  span.ant-upload-list-item-name{
    color: var(--primary-main);
  }

  .padding-0{
    padding: 0 !important;
  }

  .bg-primary-subtle{
    background-color: var(--primary-subtle);
  }

  .fw-400{
    font-weight: 400;
  }

  .misc-export-dropdown {
    .ant-dropdown-menu-item {
      max-width: 283px;
      padding: 8px 24px;

      .menu-wrapper {
        display: flex;
        flex-direction: column;

        .menu-title {
          font-size: 14px;
          font-weight: 400;
        }

        .menu-description {
          margin-top: 4px;
          font-size: 12px;
          color: var(--neutral-darker);
        }
      }
    }
  }

  .no-required-mark .ant-form-item-label > label::before {
    display: none !important;
  }

`;
