export const BORDER_RADIUS = '7px';

export const BASE_COLORS = {
  white: '#ffffff',
  primaryMain: '#ba4a00',
  primaryDarker: '#8a3200',
  primaryLighter: '#f5e4d9',
  primarySubtle: '#fcf6f2',
  accentRed: '#ff4d4f',
  accentBlue: '#1890ff',
  accentYellow: '#faad14',
  accentGreen: '#64c636',
  neutralMain: '#262626',
  neutralDarker: '#8c8c8c',
  neutralLighter: '#bfbfbf',
  neutralSubtle: '#f5f5f5',
  neutralSubtler: '#fafafa',
};

export const LAYOUT = {
  mobile: {
    paddingVertical: '0.75rem',
    paddingHorizontal: '1rem',
    headerHeight: '4.25rem',
    headerPadding: '1rem',
  },
  desktop: {
    paddingVertical: '16px',
    paddingHorizontal: '2.25rem',
    headerHeight: '64px',
  },
};

export const FONT_FAMILY = {
  main: 'Montserrat',
  secondary: 'Lato',
};

export const FONT_SIZE = {
  xxs: '0.75rem',
  xs: '0.875rem',
  md: '1rem',
  lg: '1.125rem',
  xl: '1.25rem',
  xxl: '1.5rem',
  xxxl: '1.625rem',
  xxxxl: '2rem',
};

export const FONT_WEIGHT = {
  thin: '100',
  extraLight: '200',
  light: '300',
  regular: '400',
  medium: '500',
  semibold: '600',
  bold: '700',
  extraBold: '800',
  black: '900',
};

export const BREAKPOINTS = {
  xs: 360,
  sm: 568,
  md: 768,
  lg: 992,
  xl: 1280,
  xxl: 1920,
} as const;

const getMedia = <T extends number>(breakpoint: T): `(min-width: ${T}px)` => `(min-width: ${breakpoint}px)`;

export const media = {
  xs: getMedia(BREAKPOINTS.xs),
  sm: getMedia(BREAKPOINTS.sm),
  md: getMedia(BREAKPOINTS.md),
  lg: getMedia(BREAKPOINTS.lg),
  xl: getMedia(BREAKPOINTS.xl),
  xxl: getMedia(BREAKPOINTS.xxl),
};
