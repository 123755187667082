import { ISale } from './salesTypes';

export enum RefundStatusEnum {
  Approval = 'Approval',
  Declined = 'Declined',
  Outstanding = 'Outstanding',
  InProcess = 'InProcess',
  Completed = 'Completed',
  Withdrawn = 'Withdrawn',
}

export enum RefundTypeEnum {
  Booking = 'Booking',
  Sale = 'Sale',
}

export interface IRefund {
  id: string;
  type: RefundTypeEnum;
  status: RefundStatusEnum;
  amount: number;
  adminFee: number;
  customerAdminFee: number;
  bank: string;
  accountNo: string;
  refundByDate: string;
  approvedOrRejectedDate: string;
  paymentProofs: string[];
  sale: ISale;
  createdAt: string;
  updatedAt: string;
}
