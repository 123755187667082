import { gql } from '@apollo/client';

export const GET_REFUNDS = gql`
  query Refunds($input: GetRefundsInput!) {
    refunds(input: $input) {
      refunds {
        id
        type
        status
        amount
        bank
        accountNo
        refundByDate
        approvedOrRejectedDate
        createdAt
        updatedAt
        sale {
          id
          type
          status
          finalCost
          lot {
            id
            name
          }
          products {
            product {
              productId
            }
          }
          customer {
            id
            customerId
            firstName
            lastName
          }
        }
      }
      paginationInfo {
        totalItems
        limit
        currentPage
        totalPages
        nextPage
        prevPage
        hasNextPage
        hasPrevPage
      }
    }
  }
`;

export const GET_REFUND = gql`
  query Refund($refundId: ID!) {
    refund(id: $refundId) {
      id
      type
      status
      amount
      bank
      accountNo
      refundByDate
      approvedOrRejectedDate
      paymentProofs
      createdAt
      updatedAt
      sale {
        id
        status
        customer {
          id
          firstName
          lastName
        }
      }
      salesPerson {
        id
        firstName
        lastName
      }
    }
  }
`;

export const GET_SALE_REFUND_AMOUNT = gql`
  query Query($saleRefundAmountId: ID!) {
    saleRefundAmount(id: $saleRefundAmountId)
  }
`;
