import React, { createContext, useEffect, useState, ReactNode } from 'react';
import { apolloClient } from '@app/graphql/apolloService';
import { GET_STAFF, LOGIN } from '@app/graphql/queries/staffQueries';
import { IStaff } from '@app/types/staffTypes';

const UserContext = createContext<any>({});

interface Props {
  children: ReactNode;
}

const UserContextProvider = ({ children }: Props) => {
  const [user, setUser] = useState<IStaff | undefined | false>();

  useEffect(() => {
    getUser();
  }, []);

  const getUser = async () => {
    const accessToken = localStorage.getItem('access_token');
    if (accessToken) {
      const { data } = await apolloClient.query({ query: GET_STAFF });
      setUser(data?.staff ? data.staff : false);
    } else {
      setUser(false);
    }
  };

  const logout = (): void => {
    apolloClient.clearStore();
    localStorage.removeItem('access_token');
    localStorage.removeItem('refresh_token');
    setUser(false);
  };

  const login = async (username: string, password: string): Promise<IStaff | string> => {
    try {
      const { data, errors } = await apolloClient.query({
        query: LOGIN,
        variables: { username, password },
      });
      if (errors && errors[0]) {
        throw Error(errors[0].message);
      }
      if (data.login && localStorage) {
        localStorage.setItem('access_token', data.login.access_token);
        localStorage.setItem('refresh_token', data.login.refresh_token);
        setUser(data.login);
      }
      return data.login;
    } catch (err: any) {
      return err.message;
    }
  };

  return (
    <UserContext.Provider
      value={{
        user,
        login,
        logout,
      }}
    >
      {children}
    </UserContext.Provider>
  );
};

export default UserContextProvider;
export { UserContext };
