import React from 'react';
import * as S from './MainSider/MainSider.styles';
import logo from 'assets/logo.svg';
import { useTranslation } from 'react-i18next';

interface SiderLogoProps {
  isSiderCollapsed: boolean;
  toggleSider: () => void;
}
export const SiderLogo: React.FC<SiderLogoProps> = () => {
  const { t } = useTranslation();

  return (
    <S.SiderLogoDiv>
      <S.SiderLogoLink to="/">
        <img src={logo} alt="PMP" width={63} height={24} />
        <S.BrandSpan>• {t('Sales Module')}</S.BrandSpan>
      </S.SiderLogoLink>
    </S.SiderLogoDiv>
  );
};
