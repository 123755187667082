import { ICompany } from './companyTypes';
import { IPark } from './parkTypes';
import { IPromotion } from './promotionTypes';
import { SalePaymentTypeEnum } from './salesTypes';
import { IStaff } from './staffTypes';

export enum MiscProductExtrasTypeEnum {
  Product = 'Product',
  Type = 'Type',
}

export interface IMiscProductExtras {
  id: string;
  name: string;
  type: MiscProductExtrasTypeEnum;
  product: IMiscProductExtras;
  park: IPark;
  hasDependency: boolean;
  createdAt: string;
  updatedAt: string;
}

export enum MiscProductStatusEnum {
  Available = 'Available',
  Unavailable = 'Unavailable',
}

export enum MiscProductSoldWithLotEnum {
  Yes = 'Yes',
  No = 'No',
}

export enum MiscProductAmountTypeEnum {
  Percentage = 'Percentage',
  Fixed = 'Fixed',
}

export enum MiscProductCashPaymentPlan {
  FirstMonth = 50,
  SecondMonth = 0,
  ThirdMonth = 40,
  FourthMonth = 10,
}

export interface MiscProductPromotion {
  promotion: IPromotion;
  promotionAmount: number;
  paymentType: SalePaymentTypeEnum;
}

export interface IMiscProduct {
  id: string;
  productId: string;
  status: MiscProductStatusEnum;
  description: string;
  soldWithLot: MiscProductSoldWithLotEnum;
  isTombProduct: boolean;
  isCremationProduct: boolean;
  isHearseRentalProduct: boolean;
  costInstantCase: number;
  offerCashPaymentPlan: boolean;
  offerPreNeedFullPayment: boolean;
  costPreNeedFullPayment: number;
  offerPreNeedInstalment: boolean;
  costPreNeedInstalment: number;
  downPayment: number;
  downPaymentType: MiscProductAmountTypeEnum;
  productValue: number;
  company: ICompany;
  park: IPark;
  product: IMiscProductExtras;
  type: IMiscProductExtras;
  promotions: Array<MiscProductPromotion> | [];
  createdBy: IStaff;
  updatedBy: IStaff;
  createdAt: string;
  updatedAt: string;
}
