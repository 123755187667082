import { IAgentTier } from './agentTierTypes';
import { CommissionStatusEnum } from './commissionTypes';
import { IStaff } from './staffTypes';

export enum AgentStatusEnum {
  Active = 'Active',
  Disabled = 'Disabled',
}

export interface IAgent {
  id: string;
  firstName: string;
  lastName: string;
  chineseName: string;
  fullName: string;
  nric: string;
  phone: string;
  email: string;
  address: string;
  city: string;
  postcode: string;
  state: string;
  country: string;
  status: AgentStatusEnum;
  tier: IAgentTier;
  sales: number;
  commission: number;
  commissionStatus: CommissionStatusEnum;
  createdBy: IStaff;
  updatedBy: IStaff;
  createdAt: string;
  updatedAt: string;
}
