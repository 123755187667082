import { IAgent } from './agentTypes';
import { ICustomer } from './customerTypes';
import { IMiscProduct } from './miscProductTypes';
import { IPark } from './parkTypes';
import { IPayment } from './paymentTypes';
import { PaymentVerificationStatus } from './paymentVerificationTypes';
import { IProduct } from './productTypes';
import { IPromotion } from './promotionTypes';
import { IRefund } from './refundTypes';
import { ISlot } from './slotTypes';
import { IStaff } from './staffTypes';

export enum SaleTypeEnum {
  LotSale = 'LotSale',
  MiscSale = 'MiscSale',
}

export enum SaleStatusEnum {
  Approval = 'Approval',
  Booked = 'Booked',
  Closed = 'Closed',
  Draft = 'Draft',
  Declined = 'Declined',
  Proposal = 'Proposal',
  Lost = 'Lost',
  Refunded = 'Refunded',
  Cancelled = 'Cancelled',
  Resold = 'Resold',
  PendingVerification = 'PendingVerification',
}

export enum SalePurchaseTypeEnum {
  Instant = 'Instant',
  PreNeed = 'PreNeed',
}

export enum SalePaymentTypeEnum {
  Instant = 'Instant',
  Cash = 'Cash',
  Instalment = 'Instalment',
  Deferred = 'Deferred',
  BookingExtension = 'BookingExtension',
  EditBooking = 'EditBooking',
  CashPaymentPlan = 'CashPaymentPlan',
}

export enum SaleBookingExtensionStatusEnum {
  Approval = 'Approval',
  Extended = 'Extended',
  PendingPayment = 'PendingPayment',
  Declined = 'Declined',
  Withdrawn = 'Withdrawn',
}

export enum SaleBookingExtensionPeriod {
  First = 7,
  Second = 16,
  Third = 60,
  Fourth = 0,
}

export interface SaleBookingExtension {
  _id?: string;
  extensionNo: number;
  extensionPeriod: string;
  status: SaleBookingExtensionStatusEnum;
  bookingFee: number;
  adminFee: number;
  extendedToDate?: string;
  requestedDate: string;
  approvedOrRejectedDate?: string;
  previousBookingFormUrl?: string;
  previousProposalUrl?: string;
  salesPerson: IStaff;
  approvedOrRejectedBy?: IStaff;
}

export enum SaleExtraPaymentTypeEnum {
  BookingExtension = 'BookingExtension',
  EditBooking = 'EditBooking',
}

export enum SaleEditBookingStatusEnum {
  Approval = 'Approval',
  Approved = 'Approved',
  Edited = 'Edited',
  Declined = 'Declined',
  Withdrawn = 'Withdrawn',
}
export interface SaleEditBookingParams {
  purchaseType: string;
  paymentType: string;
  topUpBookingFee: number;
  agentDiscount: number;
  otherDiscounts: number;
  agent: IAgent;
  lot: IProduct;
}
export interface ISaleEditBooking {
  id: string;
  status: SaleEditBookingStatusEnum;
  adminFee: number;
  customerAdminFee: number;
  approvedOrRejectedDate: string;
  previousBookingFormUrl: string;
  previousProposalUrl: string;
  params: SaleEditBookingParams;
  sale: ISale;
  salesPerson: IStaff;
  approvedOrRejectedBy: IStaff;
  payment: IPayment;
}

export enum PaymentOptionEnum {
  InstantCash = 'Instant cash',
  PreNeedCashFull = 'Pre-need cash In full',
  PreNeedInstalment = 'Pre-need instalment',
  CashPaymentPlan = 'Cash payment plan',
}

export interface SaleDocuments {
  proposals: Array<{ date: string; url: string }>;
  termsAndConditions?: Array<{ date: string | string; url: string }>;
  rulesAndRegulations?: Array<{ date: string | string; url: string }>;
  purchaseOrders: Array<{ date: string; url: string }>;
  bookingForms: Array<{ date: string; url: string }>;
  invoices: Array<{ date: string; url: string; number: string }>;
  receipts: Array<{ date: string; url: string; number: string }>;
  refunds: Array<{ date: string; url: string }>;
  resale: {
    novations: Array<{ date: string; url: string }>;
    deedOfAssignments: Array<{ date: string; url: string }>;
  };
  paymentProofs: Array<{ date: string; url: string }>;
  verificationProofs: Array<{ date: string; url: string }>;
  cancellationProofs: Array<{ date: string; url: string }>;
  requiredInformation?: Array<{ date: string; url: string }>;
}

export enum MiscSaleTiedWithLotEnum {
  Yes = 'Yes',
  No = 'No',
}

export interface MiscSaleProduct {
  product: IMiscProduct;
  quantity: number;
}

export interface CreatedOrUpdatedBy {
  staff: IStaff;
  date: string;
}

export interface MiscProductsPromotion {
  promotion: IPromotion;
  product: IMiscProduct;
  promotionAmount: number;
}

export interface ISale {
  id: string;
  type: SaleTypeEnum;
  orderNo: string;
  park: IPark;
  lot: IProduct;
  agent: IAgent;
  customer: ICustomer;
  additionalCustomers: ICustomer[];
  purchaseType: SalePurchaseTypeEnum;
  paymentType: SalePaymentTypeEnum;
  years: number;
  months: number;
  originalCost: number;
  licensingFee: number;
  continuityFee: number;
  aluminiumEmboss: number;
  backwallWalkway: number;
  tombPackagePrice: number;
  agentDiscount: number;
  otherDiscounts: number;
  finalCost: number;
  expirationDate: string;
  closingDate: string;
  uniqueToken: string;
  proposalUrl: string;
  bookingFormUrl: string;
  purchaseOrderUrl: string;
  gracePeriod: string | null;
  isDeferred: boolean;
  defermentAmount: number;
  status: SaleStatusEnum;
  paymentVerificationStatus: PaymentVerificationStatus;
  payment: IPayment;
  promotion: IPromotion | null;
  promotions: Array<MiscProductsPromotion> | [];
  promotionAmount: number;
  downPayment: number;
  bookingAmount: number;
  bookingExtensions: SaleBookingExtension[];
  editBooking: ISaleEditBooking;
  refund: IRefund;
  tiedToLot: MiscSaleTiedWithLotEnum;
  products: MiscSaleProduct[];
  cremationSlot: ISlot | null;
  hearseRentalSlot: ISlot | null;
  salesPerson: IStaff;
  createdAt: string;
  updatedAt: string;
  createdBy: CreatedOrUpdatedBy;
  updatedBy: CreatedOrUpdatedBy;
}
