import { ICompany } from './companyTypes';
import { StaffRoleEnum } from './staffTypes';

export enum ApprovalMatrixTypeEnum {
  BookingExtension = 'BookingExtension',
  SalesDiscount = 'SalesDiscount',
  EditBooking = 'EditBooking',
  RefundBooking = 'RefundBooking',
  Resale = 'Resale',
  SaleWithdrawal = 'SaleWithdrawal',
  MiscSaleWithdrawal = 'MiscSaleWithdrawal',
  AddNominee = 'AddNominee',
  EditNominee = 'EditNominee',
  AddBeneficiary = 'AddBeneficiary',
  EditBeneficiary = 'EditBeneficiary',
  WaiveLatePaymentInterest = 'WaiveLatePaymentInterest',
}

export enum ApprovalMatrixParameterEnum {
  AdminFee = 'AdminFee',
  BookingFee = 'BookingFee',
  SalesDiscount = 'SalesDiscount',
  AnyCircumstance = 'AnyCircumstance',
}

export enum ApprovalMatrixConditionEnum {
  LessThanOrEqualTo = 'LessThanOrEqualTo',
  GreaterThanOrEqualTo = 'GreaterThanOrEqualTo',
  EqualTo = 'EqualTo',
  BetweenOrEqualTo = 'BetweenOrEqualTo',
}

export enum ApprovalMatrixAmountTypeEnum {
  Percentage = 'Percentage',
  Fixed = 'Fixed',
}

export enum ApprovalMatrixAllowedEnum {
  Yes = 'Yes',
  No = 'No',
}

export enum WithdrawApprovalType {
  BookingExtension = 'BookingExtension',
  EditBooking = 'EditBooking',
  RefundBooking = 'RefundBooking',
  Resale = 'Resale',
  SaleWithdrawal = 'SaleWithdrawal',
  Nominee = 'Nominee',
  Beneficiary = 'Beneficiary',
  PaymentInterest = 'PaymentInterest',
}

export interface IApprovalMatrix {
  id: string;
  type: ApprovalMatrixTypeEnum;
  allowed: ApprovalMatrixAllowedEnum;
  parameter: ApprovalMatrixParameterEnum;
  condition: ApprovalMatrixConditionEnum;
  matrixNo: number;
  min: number;
  max: number;
  amount: number;
  amountType: ApprovalMatrixAmountTypeEnum;
  adminFee: number;
  adminFeeType: ApprovalMatrixAmountTypeEnum;
  approvalFrom: StaffRoleEnum;
  company: ICompany;
}
