export enum SlotEnum {
  AM = 'AM',
  PM = 'PM',
  AllDay = 'AllDay',
}

export enum SlotTime {
  AM = '09:00 - 13:00',
  PM = '13:00 - 19:00',
}

export enum SlotCapacityEnum {
  AM = 4, // 9am - 1pm
  PM = 6, // 1pm - 7pm
  AllDay = 1,
}

export enum SlotTypeEnum {
  Cremation = 'Cremation',
  HearseRental = 'HearseRental',
}

export enum SlotStatusEnum {
  Pending = 'Pending',
  Confirmed = 'Confirmed',
  Available = 'Available',
  Unavailable = 'Unavailable',
  Booked = 'Booked',
}

export interface ISlotCreate {
  slot: SlotEnum;
  type: SlotTypeEnum;
  year: number;
  month: number;
  day: number;
}

interface SlotCount {
  available: number;
  booked: number;
}

export interface ISlotAvailable {
  year: number;
  month: number;
  day: number;
  morningSlot: SlotCount;
  afternoonSlot: SlotCount;
  allDaySlot: SlotCount;
}

export interface ISlot {
  id: string;
  slot: SlotEnum;
  type: SlotTypeEnum;
  year: number;
  month: number;
  day: number;
  status: SlotStatusEnum;
  createdAt: string;
  updatedAt: string;
}
