import React, { useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { Button, ButtonProps, Dropdown } from 'antd';
import { ItemType } from 'antd/lib/menu/hooks/useItems';
import { t } from 'i18next';
import { isAuthorized, pathContains } from '@app/utils/utils';
import { StaffRoleEnum } from '@app/types/staffTypes';
import { UserContext } from '@app/context/UserContext';
import { PlusOutlined } from '@ant-design/icons';

interface Props {
  miscOnly?: boolean;
  isMiscMobile?: boolean;
  buttonText?: string;
  lotId?: string;
}

export const CreateSaleButton: React.FC<Props & ButtonProps> = ({
  miscOnly,
  isMiscMobile,
  buttonText = 'Create sales',
  lotId,
  ...props
}) => {
  const navigate = useNavigate();
  const { user } = useContext(UserContext);
  const isInCreateSalePage = pathContains([
    '/manage-sales/create',
    '/manage-sales/edit',
    '/manage-miscellaneous-sales/create',
    '/manage-miscellaneous-sales/edit',
  ]);
  const canCreateSale = isAuthorized(user, [StaffRoleEnum.Manager, StaffRoleEnum.Salesperson]);

  const dropdownOption = (option: string, navigation: string) => {
    return { label: t(option), key: option, onClick: () => navigate(navigation) };
  };

  const miscSalePath = '/manage-miscellaneous-sales/create';
  const lotIdQuery = lotId ? `?lotId=${lotId}` : '';

  const items: ItemType[] = [
    ...(miscOnly ? [] : [dropdownOption('Lot sale', '/manage-sales/create')]),
    dropdownOption('Miscellaneous sale', miscSalePath + '/others' + lotIdQuery),
    dropdownOption('Service sale', miscSalePath + '/service' + lotIdQuery),
    dropdownOption('Tomb sale', miscSalePath + '/tomb' + lotIdQuery),
  ];

  return canCreateSale && !isInCreateSalePage ? (
    <Dropdown menu={{ items }} trigger={['click']}>
      {isMiscMobile ? (
        <Button type="primary">
          <PlusOutlined />
        </Button>
      ) : (
        <Button size={'small'} {...props}>
          {t(buttonText)}
        </Button>
      )}
    </Dropdown>
  ) : (
    <></>
  );
};
