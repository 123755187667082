import { IPayment } from './paymentTypes';
import { IStaff } from './staffTypes';

export enum PaymentInterestStatusEnum {
  Approval = 'Approval',
  Approved = 'Approved',
  Declined = 'Declined',
  Waived = 'Waived',
  PartiallyWaived = 'PartiallyWaived',
  Due = 'Due',
  Paid = 'Paid',
  Withdrawn = 'Withdrawn',
}

export enum PaymentInterestTypeEnum {
  LatePaymentInterest = 'LatePaymentInterest',
}

export enum PaymentInterestWaiverTypeEnum {
  Fulfillment = 'Fulfillment',
  PartialFulfillment = 'PartialFulfillment',
  Individual = 'Individual',
}

export interface PaymentInterestPayingInterestInfo {
  payingInstallments: [IPayment];
  interestToPay: number;
  interestWaived: number;
}

export interface PaymentInterestDueInterestInfo {
  amount: number;
  status: PaymentInterestStatusEnum;
}

export interface PaymentInterestFulfilledInterestInfo {
  amount: number;
  status: PaymentInterestStatusEnum;
}

export interface IPaymentInterest {
  id: string;
  status: PaymentInterestStatusEnum;
  type: PaymentInterestTypeEnum;
  interestAmount: number;
  approvedAmount: number;
  waivedAmount: number;
  paidAmount: number;
  overDueDays: number;
  dueInterestInfo: PaymentInterestDueInterestInfo;
  fulfilledInterestInfo: PaymentInterestFulfilledInterestInfo;
  approvedOrRejectedDate: string;
  approvedOrRejectedBy: IStaff;
  payment: IPayment;
  createdAt: string;
  updatedAt: string;
}
