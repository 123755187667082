import { ILot, ILotCreate } from './lotTypes';
import { IPark } from './parkTypes';

export enum ZoneCategoryEnum {
  Land = 'Land',
  Urn = 'Urn',
}

export enum ZoneStatusEnum {
  Draft = 'Draft',
  Pending = 'Pending',
  Published = 'Published',
}

export interface IZoneCreate {
  parkId?: string;
  id?: string;
  title?: string;
  rows?: string;
  columns?: string;
  rowNames?: string[];
  columnNames?: string[];
  category?: ZoneCategoryEnum;
  status?: ZoneStatusEnum;
  image?: {
    file: string;
  };
  publish?: boolean;
  lots?: ILotCreate[] | [];
}

export interface IZonePublishedUpdate {
  id: string;
  image: string;
}

export interface IZoneDelete {
  hasRevervedProducts: boolean;
}
export interface IZoneDelete extends IZone {
  hasRevervedProducts: boolean;
  confirmed: boolean;
}

export interface IZone {
  id: string;
  title: string;
  rows: number;
  columns: number;
  rowNames: string[];
  columnNames: string[];
  category: ZoneCategoryEnum;
  status: ZoneStatusEnum;
  image: string;
  lots: ILot[];
  lotCounts: number;
  park: IPark;
}
