import { IPark } from './parkTypes';

export enum StaffStatusEnum {
  Active = 'Active',
  Blocked = 'Blocked',
  Pending = 'Pending',
}

export enum StaffRoleEnum {
  Admin = 'admin',
  SeniorSeniorManager = 'seniorSeniorManager',
  SeniorManager = 'seniorManager',
  Manager = 'manager',
  Salesperson = 'sales',
  Finance = 'finance',
}

export interface IStaff {
  id: string;
  firstName: string;
  lastName: string;
  chineseName: string;
  fullName: string;
  phone: string;
  email: string;
  role: StaffRoleEnum;
  createdAt: string;
  updatedAt: string;
  createdBy: IStaff;
  updatedBy: IStaff;
  isBlocked: boolean;
  uid: string;
  closedSales: number;
  isSuspended: boolean;
  status: StaffStatusEnum;
  parks: IPark[];
}
