import React from 'react';
import { Col, Row } from 'antd';
import * as S from '../Header.styles';
import { HeaderContent } from './HeaderContent';

interface DesktopHeaderProps {
  isTwoColumnsLayout: boolean;
  onChangeLang: (text: any) => void;
  lang: string;
  logout: () => void;
}

export const DesktopHeader: React.FC<DesktopHeaderProps> = ({ isTwoColumnsLayout, ...props }) => {
  const leftSide = isTwoColumnsLayout ? (
    <S.SearchColumn xl={16} xxl={17}>
      <Row justify="space-between"></Row>
    </S.SearchColumn>
  ) : (
    <>
      <Col lg={10} xxl={8}>
        <></>
      </Col>
    </>
  );

  return (
    <Row justify="space-between" align="middle">
      {leftSide}
      <S.ProfileColumn xl={8} xxl={7} $isTwoColumnsLayout={isTwoColumnsLayout}>
        <Row align="middle" justify="end">
          <Col>
            <Row gutter={[{ xxl: 10 }, { xxl: 10 }]} align="middle">
              <HeaderContent {...props} />
            </Row>
          </Col>
        </Row>
      </S.ProfileColumn>
    </Row>
  );
};
