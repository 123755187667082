export interface SidebarNavigationItem {
  key: string;
  url?: string;
  children?: SidebarNavigationItem[];
}

export const sidebarNavigation: SidebarNavigationItem[] = [
  {
    key: 'administration',
    children: [
      {
        key: 'parkandzones',
        url: '/manage-parks',
      },
      {
        key: 'lots',
        url: '/manage-lots',
      },
      {
        key: 'miscellaneous-products',
        url: '/manage-miscellaneous-products',
      },
      {
        key: 'staff',
        url: '/manage-staff',
      },
      {
        key: 'agentandcommisions',
        url: '/manage-agents',
      },
      {
        key: 'customers',
        url: '/manage-customers',
      },
      {
        key: 'promotions',
        url: '/manage-promotions',
      },
      {
        key: 'companies',
        url: '/manage-companies',
      },
      {
        key: 'logic',
        url: '/manage-logics',
      },
    ],
  },
  {
    key: 'sales-parent',
    children: [
      {
        key: 'lot-sales',
        url: '/manage-sales',
      },
      {
        key: 'miscellaneous-sales',
        url: '/manage-miscellaneous-sales',
      },
    ],
  },
  {
    key: 'transactions-parent',
    children: [
      {
        key: 'payments',
        url: '/manage-payments',
      },
      {
        key: 'refunds',
        url: '/manage-refunds',
      },
      {
        key: 'verifications',
        url: '/manage-verifications',
      },
    ],
  },
  {
    key: 'commissions-parent',
    children: [
      {
        key: 'product-value',
        url: '/manage-commissions/product-value',
      },
      {
        key: 'tiers-and-others',
        url: '/manage-commissions/product-value',
      },
    ],
  },
  {
    key: 'incentives-parent',
    children: [
      {
        key: 'company-targets',
        url: '/manage-incentives/company-targets',
      },
      {
        key: 'channels',
        url: '/manage-incentives/channels',
      },
      {
        key: 'staff-targets',
        url: '/manage-incentives/staff-targets',
      },
      {
        key: 'tally-and-payouts',
        url: '/manage-incentives/tally-and-payouts',
      },
    ],
  },
  {
    key: 'approvals',
    url: '/manage-approvals',
  },
  {
    key: 'approvals',
    url: '/manage-approvals',
  },
];
